import React, {useState, useEffect, useCallback} from 'react';
import Modal from '@macanta/components/Modal';
import ConnectAnotherContactForms from './ConnectAnotherContactForms';
import Button from '@macanta/components/Button';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const ConnectAnotherContactFormsBtn = ({
  connectedContactValues,
  onRenderValue,
  relationshipOptions,
  onSuccess,
  ...props
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedContactIds, setSelectedContactIds] = useState(
    Object.keys(connectedContactValues),
  );

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSuccess = useCallback((...args) => {
    console.log('onSuccess Connect Another Contact', args, onSuccess);
    onSuccess && onSuccess(...args);
    setShowModal(false);
  }, []);

  useEffect(() => {
    setSelectedContactIds(Object.keys(connectedContactValues));
  }, [connectedContactValues]);

  return (
    <>
      <Button
        onClick={handleShowModal}
        size="small"
        variant="contained"
        startIcon={<PersonAddIcon />}
        {...props}>
        Connect Another Contact
      </Button>
      <Modal
        disableScroll
        contentHeight="70%"
        headerTitle="Connect Another Contact"
        open={showModal}
        onClose={handleCloseModal}>
        <ConnectAnotherContactForms
          selectedContactIds={selectedContactIds}
          onRenderValue={onRenderValue}
          relationshipOptions={relationshipOptions}
          onSuccess={handleSuccess}
        />
      </Modal>
    </>
  );
};

export default ConnectAnotherContactFormsBtn;
