import React, {experimentalStyled as styled} from '@material-ui/core/styles';

import MUIPagination from '@material-ui/core/Pagination';
import MUITablePagination from '@material-ui/core/TablePagination';

const Pagination = ({table, ...props}) => {
  const PaginationComp = !table ? MUIPagination : MUITablePagination;
  return <PaginationComp {...props} />;
};

export const Root = styled(Pagination)`
  min-height: 64px;
  display: flex;
  align-items: center;
`;
