import {experimentalStyled as styled} from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Grid from '@macanta/components/Grid';

export const Root = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${({theme}) => theme.spacing(2)};
  overflow-y: scroll;
  position: relative;
`;

export const GridAccess = styled(Grid)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
