import {experimentalStyled as styled} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {IconButton} from '@macanta/components/Button';

export const SubHeader = styled(Typography)`
  padding-left: 0;
  padding-right: 0;
  font-size: 0.875rem;
  font-weight: bold;
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
`;

export const TopRightComp = styled(IconButton)`
  color: #bbb;
  margin-bottom: 0.5rem;
`;
