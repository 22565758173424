import {experimentalStyled as styled} from '@material-ui/core/styles';

import TextFieldComp from '@macanta/components/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export const Root = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({theme}) => theme.spacing(2)};
`;

export const fieldStyles = () => `  
  display: flex;
`;

export const Label = styled(Typography)`
  margin-bottom: ${({theme}) => theme.spacing(0.5)};
  display: inline-block;
`;

export const TextField = styled(TextFieldComp)`
  ${fieldStyles}
`;

export const TopComp = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
