import React from 'react';
import FieldForms from './FieldForms';
import Relationships from './Relationships';
import DONotes from './DONotes';
import Attachments from './Attachments';
import LoadingIndicator from '@macanta/components/LoadingIndicator';
import useDOItemMutation from './useDOItemMutation';
import * as NoteTaskHistoryStyled from '@macanta/modules/NoteTaskHistory/styles';
import * as Styled from './styles';

const DOItemForms = ({
  contactId,
  contact,
  groupId,
  relationships,
  fields,
  selectedTab,
  item,
  onSuccess,
  ...props
}) => {
  const isEdit = item?.id;
  const data = item?.data;
  const connectedContacts = item?.connectedContacts || [
    {...contact, contactId: contact.id, relationships: []},
  ];
  const notes = item?.notes || [];
  const attachments = item?.attachments || [];

  const {doItemId, saving, save} = useDOItemMutation(
    item?.id,
    groupId,
    contactId,
    relationships[0].role,
    connectedContacts,
  );

  const handleSuccess = (...args) => {
    onSuccess();

    return args;
  };

  const handleSave = (doFormKey) => async (data, disableLoading) => {
    console.log('handleSave: ' + doFormKey, data);

    return save(doFormKey, data, disableLoading).then(handleSuccess);
  };

  return (
    <Styled.Root {...props}>
      <Styled.Show in={selectedTab === 'details'}>
        <FieldForms
          isEdit={isEdit}
          fields={fields}
          data={data}
          onSave={handleSave('details')}
        />
      </Styled.Show>
      <Styled.Show in={selectedTab === 'additionalInfo'}>
        <NoteTaskHistoryStyled.FullHeightGrid container>
          <NoteTaskHistoryStyled.FullHeightFlexGridColumn item xs={12} md={4}>
            <Relationships
              contactId={contactId}
              connectedContacts={connectedContacts}
              relationships={relationships}
              onSave={handleSave('relationships')}
            />
          </NoteTaskHistoryStyled.FullHeightFlexGridColumn>
          <NoteTaskHistoryStyled.FullHeightFlexGridColumn item xs={12} md={4}>
            <DONotes data={notes} onSave={handleSave('notes')} />
          </NoteTaskHistoryStyled.FullHeightFlexGridColumn>
          <NoteTaskHistoryStyled.FullHeightFlexGridColumn item xs={12} md={4}>
            <Attachments
              data={attachments}
              doItemId={doItemId}
              onSave={handleSave('attachments')}
            />
          </NoteTaskHistoryStyled.FullHeightFlexGridColumn>
        </NoteTaskHistoryStyled.FullHeightGrid>
      </Styled.Show>
      <LoadingIndicator modal loading={saving} />
    </Styled.Root>
  );
};

DOItemForms.propTypes = {};

export default DOItemForms;
