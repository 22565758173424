import {useState, useRef} from 'react';
import {
  CREATE_OR_UPDATE_DO_ITEM,
  CONNECT_OR_DISCONNECT_RELATIONSHIP,
  CREATE_OR_UPDATE_DO_NOTE,
} from '@macanta/graphql/dataObjects';
import {useMutation} from '@apollo/client';
import useUpload from './useUpload';

const useDOItemMutation = (
  id,
  groupId,
  contactId,
  defaultRelationship,
  connectedContacts,
) => {
  const doItemIdRef = useRef(id);
  const [saving, setSaving] = useState(false);

  const [callDOItemMutation] = useMutation(CREATE_OR_UPDATE_DO_ITEM, {
    onCompleted(data = {}) {
      console.log('onCompleted CREATE_OR_UPDATE_ITEMS', data);
      if (data.createOrUpdateDataObjectItem?.id) {
        doItemIdRef.current = data.createOrUpdateDataObjectItem.id;
      }
    },
    onError() {},
  });

  const [callDORelationshipMutation] = useMutation(
    CONNECT_OR_DISCONNECT_RELATIONSHIP,
    {
      onError() {},
    },
  );

  const [callDONoteMutation] = useMutation(CREATE_OR_UPDATE_DO_NOTE, {
    onError() {},
  });

  const {callUpload} = useUpload();

  const generateDOItem = async () => {
    if (!doItemIdRef.current) {
      const {data = {}} = await callDOItemMutation({
        variables: {
          createOrUpdateDataObjectItemInput: {
            groupId,
            contactId,
            relationship: defaultRelationship,
          },
          __mutationkey: 'createOrUpdateDataObjectItemInput',
        },
      });

      return data.createOrUpdateDataObjectItem?.id;
    }

    return doItemIdRef.current;
  };

  const save = async (doFormKey, data, disableLoading) => {
    if (!disableLoading) {
      setSaving(true);
    }

    let itemId = doItemIdRef.current;

    if (!itemId) {
      itemId = await generateDOItem();
    }

    const saveForm = async () => {
      let response;

      if (doFormKey === 'details') {
        response = await saveDetails(itemId, data);
      } else if (doFormKey === 'relationships') {
        response = await saveRelationship(itemId, data);
      } else if (doFormKey === 'notes') {
        response = await saveNote(itemId, data);
      } else if (doFormKey === 'attachments') {
        response = await upload(itemId, data);
      }

      return response;
    };

    return saveForm().finally(() => {
      setSaving(false);
    });
  };

  const saveDetails = (doItemId, data) => {
    const contactRelationship = connectedContacts.find(
      (c) => c.contactId === contactId,
    );

    const relationship =
      (contactRelationship &&
        contactRelationship.relationships.map((r) => r.role).join(',')) ||
      defaultRelationship;

    return callDOItemMutation({
      variables: {
        createOrUpdateDataObjectItemInput: {
          groupId,
          doItemId,
          contactId,
          relationship,
          fields: Object.entries(data).map(([key, value]) => ({
            key,
            value,
          })),
        },
        __mutationkey: 'createOrUpdateDataObjectItemInput',
      },
    });
  };

  const saveRelationship = (doItemId, data) => {
    return callDORelationshipMutation({
      variables: {
        connectOrDisconnectRelationshipInput: {
          doItemId,
          contactId: data.connectedContactId,
          groupId,
          role: data.relationships.join(','),
        },
        __mutationkey: 'connectOrDisconnectRelationshipInput',
      },
    });
  };

  const saveNote = (doItemId, data) => {
    return callDONoteMutation({
      variables: {
        createOrUpdateDONoteInput: {
          ...data,
          doItemId,
        },
        __mutationkey: 'createOrUpdateDONoteInput',
      },
    });
  };

  const upload = (doItemId, files) => {
    return callUpload(files, doItemId);
  };

  return {
    doItemId: doItemIdRef.current,
    saving,
    save,
  };
};

export default useDOItemMutation;
