import React, {forwardRef} from 'react';
import {DropzoneArea, DropzoneDialog} from 'material-ui-dropzone';

let Dropzone = ({dialog, ...props}, ref) =>
  !dialog ? (
    <DropzoneArea ref={ref} {...props} />
  ) : (
    <DropzoneDialog ref={ref} {...props} />
  );

Dropzone = forwardRef(Dropzone);

Dropzone.defaultProps = {
  maxFileSize: 3000000,
};

export default Dropzone;
