import {
  sortArrayByPriority,
  sortArrayByKeyCondition,
} from '@macanta/utils/array';

export const convertWithSections = (itemData) => {
  return itemData.reduce((acc, d) => {
    const sections = {...acc};
    const sectionName = d.sectionTag || 'General';
    if (!sections[sectionName]) {
      sections[sectionName] = {subGroups: {}};
    }

    const section = sections[sectionName];
    const subGroups = section.subGroups;
    const subGroupName = d.subGroup || 'subGroupWithoutName';
    if (!subGroups[subGroupName]) {
      subGroups[subGroupName] = [];
    }

    const subGroup = subGroups[subGroupName];
    subGroup.push(d);

    return sections;
  }, {});
};

export const transformSectionsData = (sectionsObj) => {
  const transformedSectionsData = Object.entries(sectionsObj).map(
    ([sectionName, sectionData]) => {
      const transformedSubGroups = Object.entries(sectionData.subGroups).map(
        ([subGroupName, data]) => {
          return {
            subGroupName,
            data: data.map((d) => ({
              fieldId: d.fieldId || d.id,
              fieldName: d.name,
              value: d.value || d.default || '',
            })),
          };
        },
      );
      const sortedSubGroups = sortArrayByPriority(
        transformedSubGroups,
        'subGroupName',
        ['subGroupWithoutName'],
      );

      return {
        sectionName,
        subGroups: sortedSubGroups,
      };
    },
  );

  const sortedSectionsData = sortArrayByPriority(
    transformedSectionsData,
    'sectionName',
    ['sectionWithoutName'],
  );

  const sortedSectionsGeneral = sortArrayByKeyCondition(
    sortedSectionsData,
    'sectionName',
    (value) => value === 'General',
  );

  return sortedSectionsGeneral;
};
