import React, {useState} from 'react';
import Dropzone from '@macanta/components/Dropzone';
import ErrorBoundary from '@macanta/containers/ErrorBoundary';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import * as Styled from './styles';
import * as FormStyled from '@macanta/modules/NoteTaskForms/styles';

const UploadForms = ({onSubmit, ...props}) => {
  const [attachments, setAttachments] = useState([]);

  const handleSubmit = () => {
    onSubmit(attachments);
  };

  const handleChange = (files) => {
    console.log('handleChange files', files);
    setAttachments(files);
  };

  return (
    <Styled.Root {...props}>
      {/* TODO: Remove ErrorBoundary when material-ui-dropzone has base support for Material UI v5 */}
      <ErrorBoundary key={attachments}>
        <Dropzone
          dropzoneText="Drag and drop files or click"
          initialFiles={attachments}
          showPreviews={false}
          onChange={handleChange}
        />
      </ErrorBoundary>

      <FormStyled.Footer>
        <FormStyled.FooterButton
          variant="contained"
          startIcon={<CloudUploadIcon />}
          onClick={handleSubmit}
          size="medium">
          Upload
        </FormStyled.FooterButton>
      </FormStyled.Footer>
    </Styled.Root>
  );
};

UploadForms.propTypes = {};

export default UploadForms;
