import {experimentalStyled as styled} from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';

export const Root = styled(Chip)`
  margin: ${({theme}) => theme.spacing(1)} 0 ${({theme}) => theme.spacing(1)}
    ${({theme}) => theme.spacing(1)};
  background-color: #e0e0e0;
  border-radius: 16px;
`;
