import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import ListItemText from '@material-ui/core/ListItemText';
import * as Styled from './styles';

const FormField = ({children, label, style, TopRightComp, ...props}) => {
  let fieldChildren = children;
  let field;

  if (props.select) {
    fieldChildren = props.options.map((option) =>
      !props.multiple ? (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ) : (
        <MenuItem key={option.value} value={option.value}>
          <Checkbox checked={props.value.includes(option.value)} />
          <ListItemText primary={option.label} />
        </MenuItem>
      ),
    );
  } else if (props.radio) {
    fieldChildren = props.options.map((option) => (
      <FormControlLabel
        key={option.value}
        value={option.value}
        control={<Radio />}
        label={option.label}
      />
    ));

    field = <RadioGroup {...props}>{fieldChildren}</RadioGroup>;
  } else if (props.checkbox) {
    let values = props.value?.split('|').filter((v) => !!v) || [];

    field = props.options.map((option) => (
      <FormControlLabel
        key={option.value}
        label={option.label}
        control={
          <Checkbox
            color="primary"
            checked={values.includes(option.value)}
            onChange={() => {
              if (!values.includes(option.value)) {
                values = values.concat(option.value);
              } else {
                values = values.filter((v) => v !== option.value);
              }

              props.onChange(values.join('|'));
            }}
          />
        }
      />
    ));
  }

  if (!field) {
    field = (
      <Styled.TextField hiddenLabel {...props}>
        {fieldChildren}
      </Styled.TextField>
    );
  }

  const labelComp = label && (
    <Styled.Label variant="caption">{label}</Styled.Label>
  );

  const topComp = !TopRightComp ? (
    labelComp
  ) : (
    <Styled.TopComp>
      <Box>{labelComp}</Box>
      <Box>{TopRightComp}</Box>
    </Styled.TopComp>
  );

  return (
    <Styled.Root style={style}>
      {topComp}
      {field}
    </Styled.Root>
  );
};

export default FormField;
