import React from 'react';
import * as Styled from './styles';

const Tag = ({onDelete, label, value, ...props}) => {
  const handleDelete = () => {
    onDelete({label, value});
  };

  return (
    <Styled.Root
      label={label}
      onDelete={!onDelete ? undefined : handleDelete}
      {...props}
    />
  );
};

export default Tag;
