import {gql} from '@apollo/client';

export const NOTE_ATTRIBUTES = gql`
  fragment noteResults on Note {
    id
    accepted
    userId
    creationDate
    contactId
    completionDate
    lastUpdated
    lastUpdatedBy
    endDate
    type
    actionDate
    title
    noteType
    note
    tags
  }
`;

export const TASK_ATTRIBUTES = gql`
  fragment taskResults on Task {
    id
    accepted
    userId
    creationDate
    contactId
    completionDate
    lastUpdated
    lastUpdatedBy
    endDate
    type
    actionDate
    title
    note
    tags
    assignedBy
    assignedTo
    completed
  }
`;
