import React, {useCallback, useState} from 'react';
import Modal from '@macanta/components/Modal';
import Button from '@macanta/components/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

const NoteFormsBtn = ({isEdit, NoteFormsComp, ...props}) => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSuccess = useCallback(
    (onSuccess) => async (note) => {
      console.log('onSuccess Note', note, onSuccess);
      onSuccess && (await onSuccess(note));
      setShowModal(false);
    },
    [],
  );
  const noteForms = React.cloneElement(NoteFormsComp, {
    onSuccess: handleSuccess(NoteFormsComp.props.onSuccess),
  });

  if (!isEdit) {
    return (
      <>
        <Button
          onClick={handleShowModal}
          size="small"
          variant="contained"
          startIcon={<AddIcon />}
          {...props}>
          Add Note
        </Button>
        <Modal
          headerTitle="Add Note"
          open={showModal}
          onClose={handleCloseModal}>
          {noteForms}
        </Modal>
      </>
    );
  } else {
    return (
      <>
        <Button onClick={handleShowModal} size="small" startIcon={<EditIcon />}>
          Edit Note
        </Button>
        <Modal
          headerTitle="Edit Note"
          open={showModal}
          onClose={handleCloseModal}>
          {noteForms}
        </Modal>
      </>
    );
  }
};

export default NoteFormsBtn;
