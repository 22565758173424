import React, {useState} from 'react';
import Divider from '@material-ui/core/Divider';
import List from '@macanta/containers/List';
import * as NoteTaskHistoryStyled from '@macanta/modules/NoteTaskHistory/styles';
import NoteItem from '@macanta/modules/NoteTaskHistory/NoteItem';
import NoteFormsBtn from '@macanta/modules/NoteTaskForms/NoteFormsBtn';
import DONoteForms from './DONoteForms';
import Section from '@macanta/containers/Section';

const DONotes = ({data, onSave}) => {
  const [notes, setNotes] = useState(data || []);

  const handleSuccess = async (note) => {
    console.log('handleSuccess', note);
    await onSave(note);

    if (!note.id) {
      setNotes((state) => [note].concat(state));
    } else {
      setNotes((state) =>
        state.map((n) => {
          if (note.id === n.id) {
            return note;
          }

          return n;
        }),
      );
    }
  };

  const getKeyExtractor = (item, index) => item.id || index;

  const renderRow = (item) => {
    return (
      <NoteTaskHistoryStyled.ItemContainer>
        <NoteItem
          item={item}
          NoteFormsBtnComp={
            <NoteFormsBtn
              isEdit={true}
              NoteFormsComp={
                <DONoteForms {...item} onSuccess={handleSuccess} />
              }
            />
          }
        />
        <Divider
          style={{
            backgroundColor: '#fafafa',
          }}
        />
      </NoteTaskHistoryStyled.ItemContainer>
    );
  };

  return (
    <Section
      fullHeight
      headerStyle={{
        backgroundColor: 'white',
      }}
      style={{
        marginTop: 0,
        ...(notes.length && {
          backgroundColor: '#f5f6fa',
        }),
      }}
      title="Notes"
      HeaderRightComp={
        <NoteFormsBtn
          NoteFormsComp={<DONoteForms onSuccess={handleSuccess} />}
        />
      }>
      <List data={notes} keyExtractor={getKeyExtractor} renderRow={renderRow} />
    </Section>
  );
};

DONotes.propTypes = {};

export default DONotes;
