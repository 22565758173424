import {gql} from '@apollo/client';
import {NOTE_ATTRIBUTES} from './fragments';

export default gql`
  mutation updateNote($updateNoteInput: UpdateNoteInput!) {
    updateNote(input: $updateNoteInput) {
      ...noteResults
    }
  }
  ${NOTE_ATTRIBUTES}
`;
