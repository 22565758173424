export const convertPropertiesToArray = (obj) => {
  const entries = Object.entries(obj);
  const arr = entries.map(([key, value]) => ({key, value}));

  return arr;
};

export const getPropertiesWithValues = (obj = {}, excludes = []) => {
  const arr = convertPropertiesToArray(obj);
  const itemExists = (uniqueArr, item) => {
    return uniqueArr.some((arrValue) => arrValue.key === item.key);
  };

  const filteredArr = arr.filter((item) => {
    const itemValue = item.value;
    if (Array.isArray(itemValue)) {
      return Boolean(itemValue.length);
    } else if (typeof itemValue === 'object') {
      const nestedArr = convertPropertiesToArray(itemValue);

      return Boolean(nestedArr.length);
    } else {
      return Boolean(itemValue) && !excludes.includes(itemValue);
    }
  });

  const flattenedArr = filteredArr.reduce((acc, item) => {
    const result = acc.slice();
    const itemKey = item.key;
    const itemValue = item.value;

    if (Array.isArray(itemValue)) {
      itemValue.forEach((nestedItem, index) => {
        result.push({
          key: itemKey,
          value: nestedItem,
          id: `${itemKey}${index}`,
        });
      });
    } else if (!itemExists(acc, item)) {
      result.push({...item, id: item.key});
    }

    return result;
  }, []);

  return flattenedArr;
};

export const convertArrayToObject = (arr, defaultObj) => {
  let newObj = {...defaultObj};

  arr.forEach((item) => {
    const oldValue = defaultObj[item.key];

    if (Array.isArray(oldValue)) {
      const newValue = newObj[item.key];
      newObj[item.key] = [...newValue, item.value];
    } else {
      newObj[item.key] = item.value;
    }
  });

  return newObj;
};

export const sortArrayByObjectKey = (arr = [], key) => {
  return arr.slice().sort(compareObjectByValue(key));
};

export const sortArrayByPriority = (arr = [], key, priority = []) => {
  return arr.slice().sort(compareObjectByPriority(key, priority));
};

export const sortArrayByKeyCondition = (arr = [], key, conditionCallback) => {
  return arr.slice().sort(compareObjectByCondition(key, conditionCallback));
};

export const compareObjectByValue = (key) => (item1, item2) => {
  const item1Val = String(key ? item1[key] : item1).toLowerCase();
  const item2Val = String(key ? item2[key] : item2).toLowerCase();

  if (item1Val < item2Val) {
    return -1;
  }
  if (item1Val > item2Val) {
    return 1;
  }
  return 0;
};

export const compareObjectByPriority = (key, priority = []) => (
  item1,
  item2,
) => {
  const item1Val = key ? item1[key] : item1;
  const item2Val = key ? item2[key] : item2;

  const item1PriorityIndex = priority.indexOf(item1Val);
  const item2PriorityIndex = priority.indexOf(item2Val);

  if (item1PriorityIndex !== -1) {
    if (item2PriorityIndex !== -1 && item1PriorityIndex > item2PriorityIndex) {
      return 0;
    }

    return -1;
  }

  return 0;
};

export const compareObjectByCondition = (key, conditionCallback) => (
  item1,
  item2,
) => {
  const item1Succeeds = conditionCallback(item1[key]);
  const item2Succeeds = conditionCallback(item2[key]);

  if (item1Succeeds < item2Succeeds) {
    return 1;
  }
  if (item1Succeeds > item2Succeeds) {
    return -1;
  }

  return 0;
};

export const assignOrConcat = (currentValue, newValue) => {
  if (!currentValue) {
    return newValue;
  }

  if (Array.isArray(currentValue)) {
    return currentValue.concat(newValue);
  }

  return {...currentValue, newValue};
};
