import {gql} from '@apollo/client';
import {NOTE_ATTRIBUTES} from './fragments';

export default gql`
  mutation createNote($createNoteInput: CreateNoteInput!) {
    createNote(input: $createNoteInput) {
      ...noteResults
    }
  }
  ${NOTE_ATTRIBUTES}
`;
