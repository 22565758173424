import React, {useState} from 'react';
import Image from '@macanta/components/Image';
import Grid from '@macanta/components/Grid';
import Section from '@macanta/containers/Section';
import FileViewer from '@macanta/containers/FileViewer';
import UploadFormsBtn from './UploadFormsBtn';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import GetAppIcon from '@material-ui/icons/GetApp';
import {IconButton} from '@macanta/components/Button';
import {getAppInfo} from '@macanta/utils/app';
import * as ListStyled from '@macanta/containers/List/styles';
import * as Styled from './styles';

const Attachments = ({data, doItemId, onSave}) => {
  const [attachments, setAttachments] = useState(data || []);
  const [selectedFileSrc, setSelectedFileSrc] = useState(null);

  const handleSuccess = async (files) => {
    const [newAttachments] = await onSave(files);

    console.log('newAttachments', newAttachments);

    setAttachments((state) => newAttachments.concat(state));
  };

  const handleViewFile = (src) => () => {
    setSelectedFileSrc(src);
  };

  const handleCloseFileViewer = () => {
    setSelectedFileSrc(null);
  };

  const handleDownload = (fileId) => (event) => {
    event.stopPropagation();
    event.preventDefault();

    const [appName, apiKey] = getAppInfo();

    const url = `https://${appName}.macantacrm.com/rest/v1/data_object/download_attachments/${doItemId}/file/${fileId}?api_key=${apiKey}`;

    window.open(url);
  };

  console.log('Attachments:', attachments);

  return (
    <Section
      fullHeight
      style={{
        marginTop: 0,
      }}
      title="Attachments"
      HeaderRightComp={
        <UploadFormsBtn attachments={attachments} onSuccess={handleSuccess} />
      }>
      {attachments.length === 0 && (
        <ListStyled.EmptyMessage align="center" color="#888">
          No attachments found
        </ListStyled.EmptyMessage>
      )}
      {attachments.length > 0 && (
        <Grid container>
          {attachments.map((item, index) => {
            const base64Img = item.isNew
              ? item.thumbnail
              : `data:image/png;base64,${item.thumbnail}`;

            return (
              <Grid key={item.id || index} item xs={12} md={6} lg={4}>
                <Card
                  style={{
                    margin: '1rem',
                    ...(index % 3 !== 0 && {
                      marginLeft: 0,
                    }),
                  }}>
                  <Styled.CardActionArea
                    onClick={handleViewFile(item.downloadUrl)}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      paddingTop: '1rem',
                    }}>
                    <CardMedia
                      component={(props) => {
                        return <Image {...props} src={base64Img} />;
                      }}
                      src={base64Img}
                    />
                    <CardContent>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        style={{
                          fontSize: '0.625rem',
                        }}>
                        {item.fileName}
                      </Typography>
                    </CardContent>
                    <Styled.CardActions className="card-actions">
                      <IconButton
                        onClick={handleDownload(item.id)}
                        aria-label="download">
                        <GetAppIcon
                          style={{
                            fontSize: '0.875rem',
                            color: 'white',
                          }}
                        />
                      </IconButton>
                    </Styled.CardActions>
                  </Styled.CardActionArea>
                  {/* <CardActions>
                  <Button size="small" color="primary">
                    Share
                  </Button>
                  <Button size="small" color="primary">
                    Learn More
                  </Button>
                </CardActions> */}
                </Card>
                {/* <Paper
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  wordBreak: 'break-all',
                  padding: '1rem',
                  margin: '1rem',
                  // ...(index % 3 !== 0 && {
                  //   marginLeft: 0,
                  // }),
                }}>
                <Image src={base64Img} alt={item.fileName} />
                <Typography
                  variant="caption"
                  style={{
                    fontSize: '0.625rem',
                  }}>
                  {item.fileName}
                </Typography>
              </Paper> */}
              </Grid>
            );
          })}
        </Grid>
      )}
      <FileViewer
        src={selectedFileSrc}
        headerTitle="View File"
        onClose={handleCloseFileViewer}
        ModalProps={{
          contentWidth: '60%',
          contentHeight: '80%',
        }}
      />
    </Section>
  );
};

export default Attachments;
