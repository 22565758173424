/* eslint-disable react/jsx-filename-extension */
import React, {useState, useEffect} from 'react';
import {Router} from '@reach/router';
import LoginPage from '@macanta/modules/pages/LoginPage';
import DashboardPage from '@macanta/modules/pages/DashboardPage';
import DashboardBody from '@macanta/modules/pages/DashboardPage/DashboardBody';
import PrivateRoute from '@macanta/modules/PrivateRoute';
import BaseTheme from '@macanta/modules/BaseTheme';
import {getPersistor} from '@macanta/config/apolloConfig';

const IndexPage = () => {
  const [cacheLoaded, setCacheLoaded] = useState(false);

  useEffect(() => {
    async function init() {
      const persistor = getPersistor();

      await persistor.restore();

      setCacheLoaded(true);
    }

    init().catch(console.error);
  }, []);

  console.log('IndexPage cacheLoaded', cacheLoaded);

  return (
    cacheLoaded && (
      <BaseTheme>
        <Router>
          <PrivateRoute path="/app/dashboard" component={DashboardPage}>
            <DashboardBody path=":contactId/:slug/*metadataPath" />
          </PrivateRoute>
          <LoginPage path="/" />
        </Router>
      </BaseTheme>
    )
  );
};

export default IndexPage;
