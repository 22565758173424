import React from 'react';
import Tag from './Tag';
import * as Styled from './styles';

const Tags = ({data, TagProps, ...props}) =>
  data && data.length > 0 ? (
    <Styled.Root {...props}>
      {data.map((tag, index) => {
        return (
          <li key={index}>
            <Tag label={`#${tag}`} value={tag} {...TagProps} />
          </li>
        );
      })}
    </Styled.Root>
  ) : null;

export default Tags;
