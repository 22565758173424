import * as React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@macanta/components/Button';
import * as Styled from './styles';

const ConfirmationDialog = ({title, description, actions, ...props}) => (
  <Styled.Root {...props}>
    {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
    {description && (
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
    )}
    <DialogActions>
      {actions.map(({label, onClick, ...btnProps}, index) => {
        return (
          <Button key={index} onClick={onClick} color="primary" {...btnProps}>
            {label}
          </Button>
        );
      })}
    </DialogActions>
  </Styled.Root>
);

export default ConfirmationDialog;
