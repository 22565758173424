import React from 'react';
import {NOTE_TAG} from '@macanta/constants/noteTaskTypes';
import Form from '@macanta/components/Form';
import Typography from '@material-ui/core/Typography';
import TurnedInIcon from '@material-ui/icons/TurnedIn';
import LoadingIndicator from '@macanta/components/LoadingIndicator';
import * as Styled from '@macanta/modules/NoteTaskForms/styles';

export const filterNoteTags = (tags) => {
  return tags.filter((v) => !NOTE_TAG.TYPES.includes(v.toLowerCase()));
};

const NoteForms = ({
  initValues,
  onSubmit,
  error,
  validationSchema,
  loading,
  saveBtnIcon,
  saveBtnText,
  ...props
}) => {
  const handleSubmitFilterTags = (values) => {
    onSubmit({...values, tags: filterNoteTags(values.tags)});
  };

  return (
    <Styled.Root {...props}>
      <Styled.Container>
        <Form
          initialValues={initValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmitFilterTags}>
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            dirty,
          }) => (
            <>
              <Styled.FormGroup>
                <Styled.TextField
                  error={errors.title}
                  onChange={handleChange('title')}
                  label="Title"
                  variant="outlined"
                  value={values.title}
                  fullWidth
                  size="medium"
                />

                <Styled.TextField
                  error={errors.note}
                  onChange={handleChange('note')}
                  label="Description"
                  variant="outlined"
                  value={values.note}
                  fullWidth
                  size="medium"
                  multiline
                  rows={4}
                />

                <Styled.AddTagsField
                  error={errors.tags}
                  onChange={(value) => setFieldValue('tags', value)}
                  label="Tags"
                  variant="outlined"
                  defaultTags={filterNoteTags(values.tags)}
                  fullWidth
                  size="medium"
                />
              </Styled.FormGroup>

              {!!error && (
                <Typography color="error" variant="subtitle2" align="center">
                  {error.message}
                </Typography>
              )}

              <Styled.Footer>
                <Styled.FooterButton
                  disabled={!dirty}
                  variant="contained"
                  startIcon={saveBtnIcon || <TurnedInIcon />}
                  onClick={handleSubmit}
                  size="medium">
                  {saveBtnText || 'Save'}
                </Styled.FooterButton>
              </Styled.Footer>
            </>
          )}
        </Form>
      </Styled.Container>
      <LoadingIndicator modal loading={loading} />
    </Styled.Root>
  );
};

NoteForms.propTypes = {};

export default NoteForms;
