import * as React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';

const List = ({data, renderRow, keyExtractor, hideEmptyMessage, ...props}) => {
  return (
    <Styled.Root disablePadding={true} {...props}>
      {data.map((item, index) => {
        return (
          <Styled.ListItem key={keyExtractor(item, index)}>
            {renderRow(item, index)}
          </Styled.ListItem>
        );
      })}
      {!hideEmptyMessage && data.length === 0 && (
        <Styled.EmptyMessage align="center" color="#888">
          No items found
        </Styled.EmptyMessage>
      )}
    </Styled.Root>
  );
};

List.defaultProps = {
  data: [],
  renderRow: () => null,
  keyExtractor: (item, index) => index,
};

List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  renderRow: PropTypes.func,
  keyExtractor: PropTypes.func,
};

export default List;
