import {experimentalStyled as styled} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

export const Root = styled(Box)`
  height: 100%;
`;

export const SectionsBtnContainer = styled(Box)`
  padding: 0px ${({theme}) => theme.spacing(2)};
`;

export const BodyHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
