import React from 'react';
import {navigate} from 'gatsby';
import {isLoggedIn} from '@macanta/utils/app';

const PrivateRoute = ({component: Component, children, location, ...props}) => {
  if (!isLoggedIn() && location.pathname !== `/`) {
    navigate('/');
    return null;
  }

  return (
    <Component {...props}>
      {React.cloneElement(children, {style: {height: '100%'}})}
    </Component>
  );
};
export default PrivateRoute;
