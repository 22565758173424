import {gql} from '@apollo/client';
import {TASK_ATTRIBUTES} from './fragments';

export default gql`
  mutation updateTask($updateTaskInput: UpdateTaskInput!) {
    updateTask(input: $updateTaskInput) {
      ...taskResults
    }
  }
  ${TASK_ATTRIBUTES}
`;
