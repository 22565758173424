import React, {useState} from 'react';
import Section from '@macanta/containers/Section';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import List from '@macanta/containers/List';
import useHistoryQuery from './useHistoryQuery';
import NoteItem from './NoteItem';
import TaskItem from './TaskItem';
import ContactNoteForms from '@macanta/modules/NoteTaskForms/NoteForms/ContactNoteForms';
import NoteFormsBtn from '@macanta/modules/NoteTaskForms/NoteFormsBtn';
import * as Styled from './styles';

const NoteTaskHistory = ({contactId}) => {
  const [page, setPage] = useState(1);

  const {data, loading, pageCount} = useHistoryQuery(contactId, page - 1);

  const handleMutationSuccess = () => {
    // callHistoryQuery({contactId, page: 0, limit: 10});
  };

  const getKeyExtractor = (item, index) => item.id || index;

  const renderRow = (item) => {
    const isNote = !item.assignedTo;

    return (
      <Styled.ItemContainer>
        {isNote ? (
          <NoteItem
            item={item}
            NoteFormsBtnComp={
              <NoteFormsBtn
                isEdit={true}
                NoteFormsComp={
                  <ContactNoteForms
                    {...item}
                    onSuccess={handleMutationSuccess}
                  />
                }
              />
            }
          />
        ) : (
          <TaskItem item={item} />
        )}
        <Divider
          style={{
            backgroundColor: '#fafafa',
          }}
        />
      </Styled.ItemContainer>
    );
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Styled.FullHeightGrid container>
      <Styled.FullHeightFlexGridColumn item xs={5}>
        <Section
          fullHeight
          headerStyle={{
            backgroundColor: 'white',
          }}
          loading={loading}
          style={
            data.length && {
              backgroundColor: '#f5f6fa',
            }
          }
          title="History"
          HeaderRightComp={
            <Box>
              <Styled.TaskFormsBtn
                contactId={contactId}
                onSuccess={handleMutationSuccess}
              />
              <Styled.NoteFormsBtn
                NoteFormsComp={
                  <ContactNoteForms
                    contactId={contactId}
                    onSuccess={handleMutationSuccess}
                  />
                }
              />
            </Box>
          }
          FooterComp={
            <Styled.Pagination
              count={pageCount}
              page={page}
              onChange={handleChange}
            />
          }>
          <List
            data={data}
            keyExtractor={getKeyExtractor}
            renderRow={renderRow}
            hideEmptyMessage={loading}
          />
        </Section>
      </Styled.FullHeightFlexGridColumn>
    </Styled.FullHeightGrid>
  );
};

NoteTaskHistory.propTypes = {};

export default NoteTaskHistory;
