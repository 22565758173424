import React, {useState} from 'react';
import {experimentalStyled as styled} from '@material-ui/core/styles';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import Button from '@macanta/components/Button';
import Section from '@macanta/containers/Section';
import Typography from '@material-ui/core/Typography';
import Collapse from '@macanta/components/Collapse';
import Grid from '@macanta/components/Grid';
import Box from '@material-ui/core/Box';
import ContactFormsIconBtn from '@macanta/modules/ContactForms/ContactFormsIconBtn';
import * as Styled from './styles';

export const SectionGroup = styled(Box)`
  padding: ${({theme}) => theme.spacing(2)} 0px;
`;

const ContactDetails = ({contact, ...props}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Section
      title="Contact Details"
      HeaderRightComp={<ContactFormsIconBtn item={contact} />}
      {...props}>
      <Styled.DetailsBody>
        <SectionGroup>
          <Grid container>
            <Styled.BackgroundBox>
              <Grid item>
                <Typography component="div" display="flex">
                  <Styled.DetailLabel>Name:</Styled.DetailLabel>
                  {`${contact.firstName} ${contact.lastName}`.trim()}
                </Typography>
              </Grid>
              <Grid item>
                <Typography component="div" display="flex">
                  <Styled.DetailLabel>Email:</Styled.DetailLabel>
                  {contact.email}
                </Typography>
              </Grid>
              <Grid item>
                <Typography component="div" display="flex">
                  <Styled.DetailLabel>Phone:</Styled.DetailLabel>
                  {contact.phoneNumbers && contact.phoneNumbers[0]}
                </Typography>
              </Grid>
              <Grid item>
                <Typography component="div" display="flex">
                  <Styled.DetailLabel>Company:</Styled.DetailLabel>
                  {contact.company}
                </Typography>
              </Grid>
            </Styled.BackgroundBox>
          </Grid>
        </SectionGroup>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <SectionGroup>
            <Grid container>
              <Grid item xs={12} sm={6} md={3}>
                <Typography>
                  <Styled.DetailLabel>Contact ID:</Styled.DetailLabel>
                  {contact.id}
                </Typography>
                <Typography>
                  <Styled.DetailLabel>Title:</Styled.DetailLabel>
                  {contact.title}
                </Typography>
                <Typography>
                  <Styled.DetailLabel>Job Title:</Styled.DetailLabel>
                  {contact.jobTitle}
                </Typography>
                <Typography>
                  <Styled.DetailLabel>Date Created:</Styled.DetailLabel>
                  {contact.createdDate}
                </Typography>
                <Typography>
                  <Styled.DetailLabel>Middle Name:</Styled.DetailLabel>
                  {contact.middleName}
                </Typography>
                <Typography>
                  <Styled.DetailLabel>DOB / Birthday:</Styled.DetailLabel>
                  {contact.birthday}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography>
                  <Styled.DetailLabel>Phone 2:</Styled.DetailLabel>
                  {contact.phoneNumbers && contact.phoneNumbers[1]}
                </Typography>
                <Typography>
                  <Styled.DetailLabel>Phone 3:</Styled.DetailLabel>
                  {contact.phoneNumbers && contact.phoneNumbers[2]}
                </Typography>
                <Typography>
                  <Styled.DetailLabel>Phone 4:</Styled.DetailLabel>
                  {contact.phoneNumbers && contact.phoneNumbers[3]}
                </Typography>
                <Typography>
                  <Styled.DetailLabel>Phone 5:</Styled.DetailLabel>
                  {contact.phoneNumbers && contact.phoneNumbers[4]}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography>
                  <Styled.DetailLabel>Billing Address</Styled.DetailLabel>
                </Typography>
                <Typography>{contact.streetAddress1}</Typography>
                <Typography>{contact.streetAddress2}</Typography>
                <Typography>{contact.city}</Typography>
                <Typography>{contact.state}</Typography>
                <Typography>{contact.postalCode}</Typography>
                <Typography>{contact.country}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography>
                  <Styled.DetailLabel>Shipping Address</Styled.DetailLabel>
                </Typography>
                <Typography>{contact.address2Street1}</Typography>
                <Typography>{contact.address2Street2}</Typography>
                <Typography>{contact.city2}</Typography>
                <Typography>{contact.state2}</Typography>
                <Typography>{contact.postalCode2}</Typography>
                <Typography>{contact.country2}</Typography>
              </Grid>
            </Grid>
          </SectionGroup>
        </Collapse>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <Button
            onClick={handleExpand}
            startIcon={!expanded ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}>
            {!expanded ? 'View' : 'Hide'} Additional Info
          </Button>
        </Box>
      </Styled.DetailsBody>
    </Section>
  );
};

ContactDetails.propTypes = {};

export default ContactDetails;
