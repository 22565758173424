import {experimentalStyled as styled} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';

export const DetailsBody = styled(Box)`
  padding: 0px ${({theme}) => theme.spacing(2)};
`;

export const DetailLabel = styled(Typography)`
  color: #7f8fa6;
  font-weight: 700;
  margin-right: 5px;
`;

export const BackgroundBox = styled(Box)`
  background-color: #f5f6fa;
  border-bottom: 1px solid #7f8fa6;
  border-top: 1px solid #7f8fa6;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
`;

export const Root = styled(Box)``;
