import React, {useState, useCallback} from 'react';
import Modal from '@macanta/components/Modal';
import UploadForms from './UploadForms';
import Button from '@macanta/components/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const UploadFormsBtn = ({attachments, onSuccess, ...props}) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSuccess = useCallback(async (attachment) => {
    console.log('onSuccess Upload attachment', attachment, onSuccess);
    onSuccess && (await onSuccess(attachment));
    setShowModal(false);
  }, []);

  return (
    <>
      <Button
        onClick={handleShowModal}
        size="small"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        {...props}>
        Upload
      </Button>
      <Modal
        headerTitle="Attach Files"
        open={showModal}
        onClose={handleCloseModal}>
        <UploadForms attachments={attachments} onSubmit={handleSuccess} />
      </Modal>
    </>
  );
};

export default UploadFormsBtn;
