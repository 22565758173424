import React from 'react';

import {experimentalStyled as styled} from '@material-ui/core/styles';

export const Root = styled((props) => <ul {...props} />)`
  margin: 0;
  padding: ${({theme}) => theme.spacing(1)};
  display: flex;
  flex-wrap: wrap;
  list-style: none;
`;
