import {useEffect, useState} from 'react';
import {
  CREATE_TASK,
  UPDATE_TASK,
  LIST_TASKS,
} from '@macanta/graphql/notesTasks';
import {useMutation} from '@apollo/client';

const useTaskMutation = () => {
  const [callCreateTaskMutation, createTaskMutation] = useMutation(
    CREATE_TASK,
    {
      update(cache, {data: {createTask}}) {
        console.log('CACHE', cache);
        console.log('CREATE_TASK', createTask);
        cache.modify({
          fields: {
            listTasks(listTasksRef) {
              cache.modify({
                id: listTasksRef.__ref,
                fields: {
                  items(existingItems = []) {
                    console.log('existingItems', existingItems);
                    const newItemRef = cache.writeQuery({
                      data: createTask,
                      query: LIST_TASKS,
                    });
                    console.log('newItemRef', newItemRef);
                    return [newItemRef, ...existingItems];
                  },
                },
              });
            },
          },
        });
      },
      onError() {},
    },
  );
  const [callUpdateTaskMutation, updateTaskMutation] = useMutation(
    UPDATE_TASK,
    {
      onError() {},
    },
  );
  const [data, setData] = useState();
  const loading = Boolean(
    createTaskMutation.loading || updateTaskMutation.loading,
  );
  const error = createTaskMutation.error || updateTaskMutation.error;

  console.log(
    'createTaskMutation.data && updateTaskMutation.data',
    createTaskMutation.data,
    updateTaskMutation.data,
  );
  useEffect(() => {
    const newTask =
      createTaskMutation.data?.createTask ||
      updateTaskMutation.data?.updateTask;
    if (newTask && !loading) {
      setData(newTask);
    }
  }, [
    createTaskMutation.data?.createTask,
    updateTaskMutation.data?.updateTask,
    loading,
  ]);

  return {callCreateTaskMutation, callUpdateTaskMutation, data, loading, error};
};

export default useTaskMutation;
