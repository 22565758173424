import React, {useState, useEffect} from 'react';

import ContactDetails from '@macanta/modules/ContactDetails';
import SidebarAccess from './SidebarAccess';
import DashboardContent from './DashboardContent';
import {useNavigate} from '@reach/router';
import LoadingIndicator from '@macanta/components/LoadingIndicator';
import ContactsQuery from '@macanta/modules/hoc/ContactsQuery';
import {FETCH_POLICIES} from '@macanta/constants/fetchPolicies';
import {getDOMetaData} from './helpers';
import * as NoteTaskHistoryStyled from '@macanta/modules/NoteTaskHistory/styles';
import * as Styled from './styles';

export const getSelectedTab = (slug, metadataPath) => {
  if (slug === 'data-object') {
    const {groupId} = getDOMetaData(metadataPath);

    return groupId;
  }

  return 'notes';
};

const DashboardBody = ({contactId, slug, metadataPath, ...props}) => {
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(
    getSelectedTab(slug, metadataPath),
  );

  console.log('DashboardBody contactId', contactId);

  const handleError = () => {
    navigate(`/app/dashboard`, {
      replace: true,
    });
  };

  const handleSearchContacts = (data = {}) => {
    console.log('handleSearchContacts', data);
    const newContact = data?.listContacts?.items[0];

    if (!newContact) {
      navigate(`/app/dashboard`, {
        replace: true,
      });
    }
  };

  useEffect(() => {
    setSelectedTab(getSelectedTab(slug, metadataPath));
  }, [slug, metadataPath]);

  return (
    <Styled.Root {...props}>
      <ContactsQuery
        search={contactId}
        onCompleted={handleSearchContacts}
        onError={handleError}
        options={{fetchPolicy: FETCH_POLICIES.CACHE_FIRST}}>
        {({data, loading: contactLoading}) => {
          console.log(
            'DashboardBody ContactsQuery',
            contactLoading,
            'data',
            data,
          );
          const updatedContact = data?.listContacts?.items[0];

          return updatedContact ? (
            <>
              <ContactDetails
                contact={updatedContact}
                loading={contactLoading}
              />
              <NoteTaskHistoryStyled.FullHeightGrid container>
                <Styled.GridAccess item xs={12} sm={3} lg={2}>
                  <SidebarAccess
                    contactId={contactId}
                    selectedTab={selectedTab}
                    {...updatedContact}
                  />
                </Styled.GridAccess>
                <NoteTaskHistoryStyled.FullHeightFlexGridColumn
                  item
                  xs={12}
                  sm={9}
                  lg={10}>
                  <DashboardContent
                    contactId={contactId}
                    contact={updatedContact}
                    slug={slug}
                    metadataPath={metadataPath}
                  />
                </NoteTaskHistoryStyled.FullHeightFlexGridColumn>
              </NoteTaskHistoryStyled.FullHeightGrid>
            </>
          ) : (
            <LoadingIndicator fill align="top" />
          );
        }}
      </ContactsQuery>
    </Styled.Root>
  );
};

export default DashboardBody;
