import {gql} from '@apollo/client';
import {NOTE_ATTRIBUTES} from './fragments';

export default gql`
  query listNotes(
    $appName: String!
    $apiKey: String!
    $contactId: ID!
    $page: Int
    $limit: Int
    $filter: String
    $filterBy: String
  ) {
    listNotes(
      appName: $appName
      apiKey: $apiKey
      contactId: $contactId
      page: $page
      limit: $limit
      filter: $filter
      filterBy: $filterBy
    ) {
      items {
        ...noteResults
      }
      count
      total
    }
  }
  ${NOTE_ATTRIBUTES}
`;
