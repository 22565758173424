import React, {useState} from 'react';
import Modal from '@macanta/components/Modal';
import LoadingIndicator from '@macanta/components/LoadingIndicator';
import Box from '@material-ui/core/Box';

const FileViewer = ({ModalProps, headerTitle, src, onClose}) => {
  const [hideLoading, setHideLoading] = useState(false);
  const hasSrc = !!src;

  const hideLoadingIndicator = () => {
    console.log('hideLoadingIndicator', hideLoading);
    setHideLoading(true);
  };

  const handleClose = () => {
    setHideLoading(false);
    onClose();
  };

  return (
    <Modal
      open={hasSrc}
      headerTitle={headerTitle}
      onClose={handleClose}
      innerStyle={{
        position: 'relative',
      }}
      {...ModalProps}>
      {!hideLoading && (
        <Box
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <LoadingIndicator />
        </Box>
      )}
      <iframe
        style={{
          display: 'block',
          width: '100%',
          height: '100%',
        }}
        src={src}
        onLoad={hideLoadingIndicator}
      />
    </Modal>
  );
};

export default FileViewer;
