import {experimentalStyled as styled} from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MUIDivider from '@material-ui/core/Divider';
import ContactNoteFormsBtnComp from '@macanta/modules/NoteTaskForms/NoteFormsBtn';
import TaskFormsBtnComp from '@macanta/modules/NoteTaskForms/TaskFormsBtn';
import PaginationComp from '@macanta/components/Pagination';

export const ItemContainer = styled(Box)`
  margin-top: ${({theme}) => theme.spacing(1)};
  background-color: white;
`;

export const Divider = styled(MUIDivider)`
  background-color: #fafafa;
  margin: 0 ${({theme}) => theme.spacing(2)};
`;

export const headerRightButtonStyles = ({theme}) => `
  margin-left: ${theme.spacing(2)}
`;

export const NoteFormsBtn = styled(ContactNoteFormsBtnComp)`
  ${headerRightButtonStyles}
`;

export const TaskFormsBtn = styled(TaskFormsBtnComp)`
  ${headerRightButtonStyles}
`;

export const FullHeightGrid = styled(Grid)`
  height: 100%;
`;

export const FullHeightFlexGridColumn = styled(Grid)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Pagination = styled(PaginationComp)``;
