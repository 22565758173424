import {experimentalStyled as styled} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

export const Root = styled(Box)`
  height: 100%;
  padding: ${({theme}) => theme.spacing(2)} 0;
`;

export const Show = styled(Box)`
  height: 100%;
  ${(props) => !props.in && 'display: none;'}
`;
