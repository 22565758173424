import {gql} from '@apollo/client';

export default gql`
  query listNoteTags($appName: String!, $apiKey: String!) {
    listNoteTags(appName: $appName, apiKey: $apiKey) {
      items {
        id
        slug
        name
      }
      count
      total
    }
  }
`;
