import React from 'react';
import {TASK_TAG} from '@macanta/constants/noteTaskTypes';
import Typography from '@material-ui/core/Typography';
import * as HistoryStyled from '@macanta/modules/NoteTaskHistory/styles';
import * as Styled from './styles';
import Tags from '@macanta/modules/NoteTaskHistory/Tags';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import CompleteTaskForms from './CompleteTaskForms';

export const includeDefaultTag = (tags = []) => {
  return tags?.includes(TASK_TAG.DEFAULT) ? tags : [TASK_TAG.DEFAULT, ...tags];
};

const TaskItem = ({item}) => {
  const tags = includeDefaultTag(item.tags);

  return (
    <Styled.Root>
      <Styled.Header>
        <Box>
          <Typography variant="caption">
            {moment(item.creationDate).format('ddd DD MMM YYYY hh:mmA')}
          </Typography>
          <Styled.Title>{item.title}</Styled.Title>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Typography
            variant="caption"
            style={{
              color: '#888',
              fontSize: '.68rem',
            }}>
            Due date: {moment(item.creationDate).format('YYYY-MM-DD')}
          </Typography>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}>
            <Typography variant="caption">Done</Typography>
            <CompleteTaskForms {...item} />
          </Box>
        </Box>
      </Styled.Header>
      <HistoryStyled.Divider />
      {item.note && (
        <Styled.Body>
          <Typography>{item.note}</Typography>
        </Styled.Body>
      )}
      <Styled.AssignedContainer>
        <Typography
          variant="caption"
          style={{
            color: '#888',
            fontSize: '.68rem',
          }}>
          Assigned By: {item.assignedBy}
        </Typography>
        <Typography
          variant="caption"
          style={{
            color: '#888',
            fontSize: '.68rem',
          }}>
          Assigned To: {item.assignedTo}
        </Typography>
      </Styled.AssignedContainer>
      <Tags data={tags} />
    </Styled.Root>
  );
};

TaskItem.propTypes = {};

export default TaskItem;
