import React, {useEffect} from 'react';
import noteValidationSchema from '@macanta/validations/note';
import useNoteMutation from './useNoteMutation';
import NoteForms from './NoteForms';

const ContactNoteForms = ({
  id,
  contactId,
  title = '',
  note = '',
  tags = [],
  onSuccess,
}) => {
  const isEdit = !!id;

  const initValues = {
    ...(isEdit ? {id} : {contactId}),
    title,
    note,
    tags,
  };

  const {
    callCreateNoteMutation,
    callUpdateNoteMutation,
    data,
    loading,
    error,
  } = useNoteMutation();

  console.log('note initValues', initValues);
  console.log('note data', data, loading, error);

  const handleFormSubmit = (values) => {
    console.log('note handleFormSubmit values', values);

    if (!isEdit) {
      callCreateNoteMutation({
        variables: {
          createNoteInput: values,
          __mutationkey: 'createNoteInput',
        },
      });
    } else {
      callUpdateNoteMutation({
        variables: {
          updateNoteInput: values,
          __mutationkey: 'updateNoteInput',
        },
      });
    }
  };

  useEffect(() => {
    if (data) {
      onSuccess(data);
    }
  }, [data]);

  return (
    <NoteForms
      initValues={initValues}
      validationSchema={noteValidationSchema}
      onSubmit={handleFormSubmit}
      error={error}
      loading={loading}
    />
  );
};

ContactNoteForms.propTypes = {};

export default ContactNoteForms;
