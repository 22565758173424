import {useEffect, useState} from 'react';
import {
  CREATE_NOTE,
  UPDATE_NOTE,
  LIST_NOTES,
} from '@macanta/graphql/notesTasks';
import {useMutation} from '@apollo/client';

const useNoteMutation = () => {
  const [callCreateNoteMutation, createNoteMutation] = useMutation(
    CREATE_NOTE,
    {
      update(cache, {data: {createNote}}) {
        console.log('CACHE', cache);
        console.log('CREATE_NOTE', createNote);
        cache.modify({
          fields: {
            listNotes(listNotesRef) {
              cache.modify({
                id: listNotesRef.__ref,
                fields: {
                  items(existingItems = []) {
                    console.log('existingItems', existingItems);
                    const newItemRef = cache.writeQuery({
                      data: createNote,
                      query: LIST_NOTES,
                    });
                    console.log('newItemRef', newItemRef);
                    return [newItemRef, ...existingItems];
                  },
                },
              });
            },
          },
        });
      },
      onError() {},
    },
  );
  const [callUpdateNoteMutation, updateNoteMutation] = useMutation(
    UPDATE_NOTE,
    {
      onError() {},
    },
  );
  const [data, setData] = useState();
  const loading = Boolean(
    createNoteMutation.loading || updateNoteMutation.loading,
  );
  const error = createNoteMutation.error || updateNoteMutation.error;

  console.log(
    'createNoteMutation.data && updateNoteMutation.data',
    createNoteMutation.data,
    updateNoteMutation.data,
  );
  useEffect(() => {
    const newNote =
      createNoteMutation.data?.createNote ||
      updateNoteMutation.data?.updateNote;
    if (newNote && !loading) {
      setData(newNote);
    }
  }, [
    createNoteMutation.data?.createNote,
    updateNoteMutation.data?.updateNote,
    loading,
  ]);

  return {callCreateNoteMutation, callUpdateNoteMutation, data, loading, error};
};

export default useNoteMutation;
