export const getContactRelationships = (contacts) => {
  const allContactRelationships = [];

  contacts.forEach((contact) => {
    const contactRelationships = contact.relationships.map((r) => r.role);
    contactRelationships.forEach((r) => {
      if (!allContactRelationships.includes(r)) {
        allContactRelationships.push(r);
      }
    });
  });

  return allContactRelationships;
};

export const getFilteredRelationshipsByLimit = (
  contactRelationships,
  allRelationships,
) => {
  const filteredRelationships = allRelationships.filter((r) => {
    if (!r.hasLimit) {
      return true;
    }

    return !contactRelationships.includes(r.role);
  });

  return filteredRelationships.map((r) => r.role);
};

export const getRelationshipOptions = (
  allRelationships,
  selectedValues,
  filteredRelationships,
) => {
  return allRelationships
    .filter(
      (item) =>
        selectedValues.includes(item) || filteredRelationships.includes(item),
    )
    .map((item) => ({label: item, value: item}));
};
