import {experimentalStyled as styled} from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export const Root = styled(Box)``;

export const Title = styled(Typography)``;

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: ${({theme}) => theme.spacing(2)};
`;

export const Body = styled(Box)`
  padding: ${({theme}) => theme.spacing(2)};
`;

export const AssignedContainer = styled(Box)`
  padding: 0 ${({theme}) => theme.spacing(2)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({theme}) => theme.spacing(2)};
`;
