import React, {useEffect} from 'react';
import {TASK_TAG} from '@macanta/constants/noteTaskTypes';
import taskValidationSchema from '@macanta/validations/task';
import Form from '@macanta/components/Form';
import Typography from '@material-ui/core/Typography';
import TurnedInIcon from '@material-ui/icons/TurnedIn';
import LoadingIndicator from '@macanta/components/LoadingIndicator';
import useTaskMutation from './useTaskMutation';
import * as Styled from '@macanta/modules/NoteTaskForms/styles';
import SelectUserField from '@macanta/modules/NoteTaskForms/SelectUserField';
import * as Storage from '@macanta/utils/storage';

export const filterTaskTags = (tags) => {
  return tags.filter((v) => !TASK_TAG.TYPES.includes(v.toLowerCase()));
};

const TaskForms = (props) => {
  const {email: defaultUserEmail} = Storage.getItem('userDetails');

  const {
    id,
    contactId,
    userId,
    title = '',
    userEmail = defaultUserEmail,
    note = '',
    tags = [],
    onSuccess,
  } = props;

  const isEdit = !!id;

  const initValues = {
    ...(isEdit ? {id} : {contactId}),
    title,
    userEmail,
    note,
    userId,
    tags,
  };

  const {callCreateTaskMutation, data, loading, error} = useTaskMutation();

  console.log('task initValues', initValues, defaultUserEmail);
  console.log('task data', data, loading, error);

  const handleFormSubmit = (values) => {
    console.log('task handleFormSubmit values', values);

    callCreateTaskMutation({
      variables: {
        createTaskInput: values,
        __mutationkey: 'createTaskInput',
      },
    });
  };

  const handleSubmitFilterTags = (values) => {
    handleFormSubmit({...values, tags: filterTaskTags(values.tags)});
  };

  useEffect(() => {
    if (data) {
      onSuccess(data);
    }
  }, [data]);

  return (
    <Styled.Root {...props}>
      <Styled.Container>
        <Form
          initialValues={initValues}
          validationSchema={taskValidationSchema}
          onSubmit={handleSubmitFilterTags}>
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            dirty,
          }) => (
            <>
              <Styled.FormGroup>
                <Styled.TextField
                  error={errors.title}
                  onChange={handleChange('title')}
                  label="Title"
                  variant="outlined"
                  value={values.title}
                  fullWidth
                  size="medium"
                />

                <SelectUserField
                  error={errors.userEmail}
                  onChange={handleChange('userEmail')}
                  label="Assign To (Macanta User)"
                  variant="outlined"
                  value={values.userEmail}
                  fullWidth
                  size="medium"
                />

                <Styled.TextField
                  date
                  error={errors.actionDate}
                  onChange={handleChange('actionDate')}
                  label="Due Date"
                  variant="outlined"
                  value={values.actionDate}
                  fullWidth
                  size="medium"
                  DatePickerProps={{
                    inputFormat: 'yyyy-MM-dd',
                  }}
                />

                <Styled.TextField
                  error={errors.note}
                  onChange={handleChange('note')}
                  label="Description"
                  variant="outlined"
                  value={values.note}
                  fullWidth
                  size="medium"
                  multiline
                  rows={4}
                />

                <Styled.AddTagsField
                  error={errors.tags}
                  onChange={(value) => setFieldValue('tags', value)}
                  label="Tags"
                  variant="outlined"
                  defaultTags={filterTaskTags(values.tags)}
                  fullWidth
                  size="medium"
                />
              </Styled.FormGroup>

              {!!error && (
                <Typography color="error" variant="subtitle2" align="center">
                  {error.message}
                </Typography>
              )}

              <Styled.Footer>
                <Styled.FooterButton
                  disabled={!dirty}
                  variant="contained"
                  startIcon={<TurnedInIcon />}
                  onClick={handleSubmit}
                  size="medium">
                  Save
                </Styled.FooterButton>
              </Styled.Footer>
            </>
          )}
        </Form>
      </Styled.Container>
      <LoadingIndicator modal loading={loading} />
    </Styled.Root>
  );
};

TaskForms.propTypes = {};

export default TaskForms;
