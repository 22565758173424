import React from 'react';
import noteValidationSchema from '@macanta/validations/note';
import NoteForms from '@macanta/modules/NoteTaskForms/NoteForms';

const DONoteForms = ({
  id,
  title = '',
  note = '',
  tags = [],
  onSuccess,
  ...props
}) => {
  const initValues = {
    id,
    title,
    note,
    tags,
  };

  return (
    <NoteForms
      initValues={initValues}
      validationSchema={noteValidationSchema}
      onSubmit={onSuccess}
      {...props}
    />
  );
};

DONoteForms.propTypes = {};

export default DONoteForms;
