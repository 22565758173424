import React, {useCallback, useState} from 'react';
import Modal from '@macanta/components/Modal';
import TaskForms from '@macanta/modules/NoteTaskForms/TaskForms';
import Button from '@macanta/components/Button';
import AddIcon from '@material-ui/icons/Add';

const TaskFormsBtn = ({contactId, onSuccess, ...props}) => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSuccess = useCallback((task) => {
    console.log('onSuccess Task', task, onSuccess);
    onSuccess && onSuccess(task);
    setShowModal(false);
  }, []);

  return (
    <>
      <Button
        onClick={handleShowModal}
        size="small"
        variant="contained"
        startIcon={<AddIcon />}
        {...props}>
        Add Task
      </Button>
      <Modal headerTitle="Add Task" open={showModal} onClose={handleCloseModal}>
        <TaskForms contactId={contactId} onSuccess={handleSuccess} />
      </Modal>
    </>
  );
};

export default TaskFormsBtn;
