import React, {useState, useEffect} from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import {LIST_USERS} from '@macanta/graphql/users';
import {useQuery} from '@apollo/client';
import * as Styled from './styles';

const SelectUserField = (props) => {
  const [users, setUsers] = useState(
    props.value ? [{label: props.value, value: props.value}] : [],
  );
  const usersQuery = useQuery(LIST_USERS, {
    variables: {
      page: 0,
      limit: 100,
    },
  });

  useEffect(() => {
    const updatedUsers = usersQuery.data?.listUsers.items;
    if (updatedUsers && !usersQuery.loading) {
      const transformedData = updatedUsers.map((user) => ({
        label: user.email, //TODO: check if this should be replaced by full name `${user.firstName} ${user.lastName}`.trim(),
        value: user.email,
      }));

      setUsers(transformedData);
    }
  }, [usersQuery.data?.listUsers, usersQuery.loading]);

  return (
    <Styled.TextField {...props} select>
      {users.map((cat) => (
        <MenuItem key={cat.value} value={cat.value}>
          {cat.label}
        </MenuItem>
      ))}
    </Styled.TextField>
  );
};

SelectUserField.defaultProps = {
  defaultTags: [],
  onChange: () => {},
};

export default SelectUserField;
