import {experimentalStyled as styled} from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@macanta/components/Button';
import TextFieldComp from '@macanta/components/TextField';
import AddTagsFieldComp from './AddTagsField';

export const Root = styled(Box)``;

export const fieldStyles = ({theme}) => `
  margin-bottom: ${theme.spacing(2)};
  display: flex;
`;

export const TextField = styled(TextFieldComp)`
  ${fieldStyles}
`;

export const Select = styled(TextFieldComp)`
  ${fieldStyles}
`;

export const AddTagsField = styled(AddTagsFieldComp)`
  ${fieldStyles}
  margin-bottom: 0;
`;

export const AddTagsFieldRoot = styled(Box)`
  margin-bottom: ${({theme}) => theme.spacing(2)};
`;

export const AddTagsFieldFooter = styled(Box)`
  margin: ${({theme}) => theme.spacing(1)} 0;
  height: 3rem;
`;

export const Container = styled(Box)`
  padding: 0px ${({theme}) => theme.spacing(2)};
`;

export const FormGroup = styled(Box)`
  margin: ${({theme}) => theme.spacing(2)} 0px;
`;

export const Footer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: ${({theme}) => theme.spacing(2)} 0;
`;

export const FooterButton = styled(Button)`
  margin-left: ${({theme}) => theme.spacing(2)};
`;
