import {gql} from '@apollo/client';
import {TASK_ATTRIBUTES} from './fragments';

export default gql`
  mutation createTask($createTaskInput: CreateTaskInput!) {
    createTask(input: $createTaskInput) {
      ...taskResults
    }
  }
  ${TASK_ATTRIBUTES}
`;
