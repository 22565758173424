import React, {useState, useEffect} from 'react';

import TextField from '@macanta/components/TextField';
import Tags from '@macanta/modules/NoteTaskHistory/Tags';
import {LIST_NOTE_TAGS} from '@macanta/graphql/notesTasks';
import {useQuery} from '@apollo/client';
import * as Styled from './styles';

const removeTagSign = (tag) => tag.replace(/^#+/i, '');

const convertSpaceToUnderscore = (text) => text.replace(/\s+/g, '_');

const getOptionLabel = (option) => {
  return option?.name || option;
};

const AddTagsField = ({defaultTags, onChange, ...props}) => {
  const [tags, setTags] = useState(defaultTags);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsWithValue, setSuggestionsWithValue] = useState([]);
  const [value, setValue] = useState('');

  const noteTagsQuery = useQuery(LIST_NOTE_TAGS, {});

  const setTagsFn = (updatedTags) => {
    console.log('updatedTags', updatedTags);
    setTags(updatedTags);
    onChange(updatedTags);
  };

  const addTag = (newTag) => () => {
    console.log('addTag', newTag);
    const formattedTag = removeTagSign(newTag);

    if (formattedTag && !tags.includes(formattedTag)) {
      setTagsFn(tags.concat(formattedTag));
      setValue('');
    }
  };

  const removeTag = (tagToDelete) => {
    console.log('removeTag', tags, tagToDelete);
    setTagsFn(tags.filter((tag) => tag !== tagToDelete.value));
  };

  const handleTagTextChange = (text = '') => {
    console.log('handleTagTextChange', text);
    const replacedText = convertSpaceToUnderscore(text);
    console.log('replacedText', replacedText);
    setValue(replacedText);
  };

  const handleOptionSelect = (tag) => {
    console.log('handleOptionSelect', tag);
    addTag(tag.name)();
  };

  useEffect(() => {
    if (noteTagsQuery.data?.listNoteTags.items) {
      const options = noteTagsQuery.data?.listNoteTags.items;

      const filteredSuggestions = options.filter(
        (s) => !tags.includes(removeTagSign(s.name)),
      );

      console.log('filteredSuggestions', filteredSuggestions, options, tags);

      setSuggestions(filteredSuggestions);
    }
  }, [noteTagsQuery.data?.listNoteTags.items, tags]);

  useEffect(() => {
    setSuggestionsWithValue(
      value
        ? [
            {
              name: `#${value}`,
            },
          ].concat(suggestions.filter((s) => removeTagSign(s.name) !== value))
        : suggestions,
    );
  }, [suggestions, value]);

  return (
    <Styled.AddTagsFieldRoot>
      <TextField
        {...props}
        onChange={handleTagTextChange}
        onBlur={addTag(value)}
        value={value}
        search
        clearOnSelect
        onSelectOption={handleOptionSelect}
        AutoCompleteProps={{
          options: suggestionsWithValue,
          getOptionLabel,
        }}
      />
      <Styled.AddTagsFieldFooter>
        <Tags
          style={{
            padding: 0,
          }}
          data={tags}
          TagProps={{
            onDelete: removeTag,
          }}
        />
      </Styled.AddTagsFieldFooter>
    </Styled.AddTagsFieldRoot>
  );
};

AddTagsField.defaultProps = {
  defaultTags: [],
  options: [],
  onChange: () => {},
};

export default AddTagsField;
