import React, {useState, useEffect} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@macanta/components/Alert';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  alert: {
    width: '100%',
  },
}));

const ProgressAlert = ({open: openProp, message, onClose, ...props}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    onClose && onClose();
  };

  useEffect(() => {
    setOpen(openProp);
  }, [openProp]);

  return (
    <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
      <Alert
        style={{
          backgroundColor: '#4caf50',
          color: 'white',
        }}
        onClose={handleClose}
        severity="success"
        className={classes.alert}
        {...props}>
        {message}
      </Alert>
    </Snackbar>
  );
};

ProgressAlert.defaultProps = {
  open: false,
};

export default ProgressAlert;
