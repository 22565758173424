import {useMemo} from 'react';
import {LIST_NOTES, LIST_TASKS} from '@macanta/graphql/notesTasks';
import {useQuery} from '@apollo/client';
import usePrevious from '@macanta/hooks/usePrevious';

const useHistoryQuery = (contactId, page = 0, limit = 10) => {
  const notesQuery = useQuery(LIST_NOTES, {
    variables: {contactId, page, limit},
  });
  const tasksQuery = useQuery(LIST_TASKS, {
    variables: {contactId, page, limit},
  });

  // useMemo to prevent unnecessary sorting computation
  const [total, pageCount] = useMemo(() => {
    const newTotal =
      (notesQuery.data?.listNotes.total || 0) +
      (tasksQuery.data?.listTasks.total || 0);

    const newPageCount = parseInt(newTotal / 20);

    return [newTotal, newPageCount];
  }, [notesQuery.data?.listNotes.total, tasksQuery.data?.listTasks.total]);

  const data = useMemo(
    () =>
      [
        ...(notesQuery.data?.listNotes.items || []),
        ...(tasksQuery.data?.listTasks.items || []),
      ].sort((item1, item2) => {
        if (item1.creationDate < item2.creationDate) {
          return 1;
        } else if (item1.creationDate > item2.creationDate) {
          return -1;
        }

        return 0;
      }),
    [notesQuery.data?.listNotes.items, tasksQuery.data?.listTasks.items],
  );

  // usePrevious to get previous truthy value and check undefined later on
  const prevData = usePrevious(data);
  const prevTotal = usePrevious(total);
  const prevPageCount = usePrevious(pageCount);

  const loading = Boolean(notesQuery.loading || tasksQuery.loading);
  const error = notesQuery.error || tasksQuery.error;

  console.log(
    'notesQuery.data?.listNotes.total',
    notesQuery.data?.listNotes.total,
  );

  return {
    data: data || prevData,
    loading,
    error,
    total: total || prevTotal,
    pageCount: pageCount || prevPageCount,
  };
};

export default useHistoryQuery;
