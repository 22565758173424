import {experimentalStyled as styled} from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import UniversalSearchComp from '@macanta/modules/UniversalSearch';
import {fillScroll} from '@macanta/containers/Section/styles';

export const Root = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({theme}) => theme.spacing(2)};
`;

export const Body = styled(Box)`
  flex: 1;
  position: relative;
  padding-bottom: ${({theme}) => theme.spacing(2)};
`;

export const ContactSearchContainer = styled(Box)`
  ${fillScroll}
  overflow-y: hidden;
`;

export const UniversalSearch = styled(UniversalSearchComp)`
  margin-bottom: ${({theme}) => theme.spacing(2)};
`;
