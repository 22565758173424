import {experimentalStyled as styled} from '@material-ui/core/styles';
import ButtonGroup from '@macanta/components/ButtonGroup';
import Button from '@macanta/components/Button';

export const TitleCrumbBtn = styled(Button)`
  padding: 0;
`;

export const DOItemNavBtnGroup = styled(ButtonGroup)``;

export const DOItemNavBtn = styled(Button)`
  font-size: 0.9rem;
  padding-left: ${({theme}) => theme.spacing(1.6)};
  padding-right: ${({theme}) => theme.spacing(1.6)};
`;
