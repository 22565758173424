import {gql} from '@apollo/client';

export default gql`
  query listUsers(
    $appName: String!
    $apiKey: String!
    $page: Int
    $limit: Int
  ) {
    listUsers(appName: $appName, apiKey: $apiKey, page: $page, limit: $limit) {
      items {
        id
        email
      }
      count
      total
    }
  }
`;
