import React, {forwardRef} from 'react';
import MUIAlert from '@material-ui/core/Alert';

let Alert = (props, ref) => (
  <MUIAlert ref={ref} elevation={6} variant="filled" {...props} />
);

Alert = forwardRef(Alert);

export default Alert;
