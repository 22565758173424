import React from 'react';
import NoteTaskHistory from '@macanta/modules/NoteTaskHistory';
import DataObjectItems from '@macanta/modules/DataObjectItems';
import {getDOMetaData} from '@macanta/modules/pages/DashboardPage/DashboardBody/helpers';

const DashboardContent = ({slug, metadataPath, contactId, contact}) => {
  let sidebarContent = null;

  if (slug === 'notes') {
    sidebarContent = <NoteTaskHistory contactId={contactId} />;
  } else if (slug === 'data-object') {
    const {groupId, doTitle} = getDOMetaData(metadataPath);

    sidebarContent = (
      <DataObjectItems
        contactId={contactId}
        contact={contact}
        groupId={groupId}
        doTitle={doTitle}
      />
    );
  }

  return sidebarContent;
};

DashboardContent.propTypes = {};

export default DashboardContent;
