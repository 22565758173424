import React from 'react';
import {NOTE_TAG} from '@macanta/constants/noteTaskTypes';
import Typography from '@material-ui/core/Typography';
import * as HistoryStyled from '@macanta/modules/NoteTaskHistory/styles';
import * as Styled from './styles';
import Tags from '@macanta/modules/NoteTaskHistory/Tags';
import Box from '@material-ui/core/Box';
import moment from 'moment';

export const includeDefaultTag = (tags = []) => {
  return tags?.includes(NOTE_TAG.DEFAULT) ? tags : [NOTE_TAG.DEFAULT, ...tags];
};

const NoteItem = ({item, NoteFormsBtnComp}) => {
  const tags = includeDefaultTag(item.tags);

  return (
    <Styled.Root>
      <Styled.Header>
        <Box>
          <Typography variant="caption">
            {moment(item.creationDate).format('ddd DD MMM YYYY hh:mmA')}
          </Typography>
          <Styled.Title>{item.title}</Styled.Title>
        </Box>
        {NoteFormsBtnComp}
      </Styled.Header>
      <HistoryStyled.Divider />
      {item.note && (
        <Styled.Body>
          <Typography>{item.note}</Typography>
        </Styled.Body>
      )}
      <Tags data={tags} />
    </Styled.Root>
  );
};

NoteItem.propTypes = {};

export default NoteItem;
