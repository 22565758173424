import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import TextField from '@macanta/components/TextField';
import Form from '@macanta/components/Form';
import connectContactValidationSchema from '@macanta/validations/connectContact';
import * as FormStyled from '@macanta/modules/NoteTaskForms/styles';
import * as Styled from './styles';

const DEFAULT_COLUMNS = [
  {id: 'name', label: 'Name', minWidth: 170},
  {id: 'email', label: 'Email', minWidth: 170},
];

const DO_SELECTED_CONTACT_TABLE_PROPS = {
  highlight: true,
  hidePagination: true,
};

const ConnectAnotherContactForms = ({
  onRenderValue,
  relationshipOptions,
  onSuccess,
  ...props
}) => {
  const handleFormSubmit = ({contact, relationships}) => {
    console.log(
      'ConnectAnotherContactForms handleFormSubmit',
      contact,
      relationships,
    );
    onSuccess(contact, relationships);
  };

  return (
    <Styled.Root {...props}>
      <Form
        initialValues={{}}
        validationSchema={connectContactValidationSchema}
        onSubmit={handleFormSubmit}>
        {({values, errors, handleSubmit, setFieldValue}) => (
          <>
            <Styled.Body>
              <Styled.ContactSearchContainer>
                <Styled.UniversalSearch
                  autoFocus
                  autoSearch
                  defaultSearchQuery={{search: '', page: 0, limit: 10}}
                  onSelectItem={(contact) => setFieldValue('contact', contact)}
                  error={errors.contact}
                  disableCategories
                  disableSuggestions
                  columns={DEFAULT_COLUMNS}
                  TableProps={DO_SELECTED_CONTACT_TABLE_PROPS}
                  RightComp={
                    <>
                      <Box
                        style={{
                          marginLeft: '0.5rem',
                          marginRight: '0.5rem',
                        }}
                      />
                      <TextField
                        select
                        multiple
                        size="small"
                        hiddenLabel
                        error={errors.relationships}
                        onChange={(event) =>
                          setFieldValue('relationships', event.target.value)
                        }
                        value={values.relationships || []}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        placeholder="Choose relationships..."
                        style={{
                          flex: 1,
                          backgroundColor: 'white',
                        }}
                        renderValue={onRenderValue}>
                        {relationshipOptions.map((cat) => (
                          <MenuItem key={cat.value} value={cat.value}>
                            {cat.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </>
                  }
                />
              </Styled.ContactSearchContainer>
            </Styled.Body>
            <FormStyled.Footer>
              <FormStyled.FooterButton
                variant="contained"
                startIcon={<PersonAddIcon />}
                onClick={handleSubmit}
                size="medium">
                Connect
              </FormStyled.FooterButton>
            </FormStyled.Footer>
          </>
        )}
      </Form>
    </Styled.Root>
  );
};

ConnectAnotherContactForms.propTypes = {};

export default ConnectAnotherContactForms;
