import React, {useEffect, useState, useRef} from 'react';
import {convertWithSections, transformSectionsData} from './transforms';
import Grid from '@macanta/components/Grid';
import ButtonGroup from '@macanta/components/ButtonGroup';
import Button from '@macanta/components/Button';
import {SubSection} from '@macanta/containers/Section';
import FormField from '@macanta/containers/FormField';
import TurnedInIcon from '@material-ui/icons/TurnedIn';
import Form from '@macanta/components/Form';
import * as Styled from './styles';

const getSectionsData = (itemData) => {
  const sectionsObj = convertWithSections(itemData);

  const transformedSectionsData = transformSectionsData(sectionsObj);

  return transformedSectionsData;
};

const getFieldAttrs = (type, field) => {
  let fieldAttrs = {};

  switch (type) {
    case 'TextArea': {
      fieldAttrs = {
        multiline: true,
        rows: 4,
      };
      break;
    }
    case 'Select': {
      fieldAttrs = {
        select: true,
        placeholder: 'Select...',
        options: field.choices.map((choice) => ({
          label: choice,
          value: choice,
        })),
      };
      break;
    }
    case 'Date': {
      fieldAttrs = {
        date: true,
        DatePickerProps: {
          inputFormat: 'yyyy-MM-dd',
        },
      };
      break;
    }
    case 'DateTime': {
      fieldAttrs = {
        datetime: true,
        DateTimePickerProps: {
          inputFormat: 'yyyy-MM-dd hh:mma',
        },
      };
      break;
    }
    case 'Radio': {
      fieldAttrs = {
        radio: true,
        defaultValue: field.default,
        options: field.choices.map((choice) => ({
          label: choice,
          value: choice,
        })),
      };
      break;
    }
    case 'Checkbox': {
      fieldAttrs = {
        checkbox: true,
        options: field.choices.map((choice) => ({
          label: choice,
          value: choice,
        })),
      };
      break;
    }
  }

  return fieldAttrs;
};

const FieldForms = ({isEdit, fields, data, onSave}) => {
  console.log('FieldForms', isEdit, data, fields);

  const [sectionsData, setSectionsData] = useState([]);
  const [sectionNames, setSectionNames] = useState([]);
  const [filteredSectionsData, setFilteredSectionsData] = useState(null);
  const initValuesRef = useRef(null);

  const handleSelectSectionData = (sectionName) => () => {
    const filteredData = sectionsData.find(
      (section) => section.sectionName === sectionName,
    );

    console.log(
      'handleSelectSectionData',
      sectionName,
      sectionsData,
      filteredData,
    );

    setFilteredSectionsData(filteredData);
  };

  const getInitValues = (sections) => {
    return sections.reduce((acc, section) => {
      section.subGroups.forEach((subGroup) => {
        subGroup.data.forEach((fieldData) => {
          const itemData = data.find((d) => d.fieldId === fieldData.fieldId);
          acc[fieldData.fieldName] = itemData?.value || fieldData.value; // if itemData.value is empty, get default value from fieldData.value
        });
      });
      return acc;
    }, {});
  };

  const handleSave = async (values, actions) => {
    await onSave(values);
    actions.resetForm({values});
  };

  useEffect(() => {
    if (fields) {
      const allSections = getSectionsData(fields);
      const allSectionNames = allSections.map((section) => section.sectionName);

      initValuesRef.current = getInitValues(allSections);

      setSectionsData(allSections);
      setSectionNames(allSectionNames);
      console.log('allSectionNames', allSectionNames);
    }
  }, [fields]);

  useEffect(() => {
    if (sectionNames) {
      handleSelectSectionData(sectionNames[0])();
    }
  }, [sectionNames]);

  console.log('filteredSectionsData', filteredSectionsData, sectionsData);
  console.log('initValues', initValuesRef.current);

  if (!filteredSectionsData) {
    return null;
  }

  return (
    <Styled.Root>
      <Form
        initialValues={initValuesRef.current}
        // validationSchema={taskValidationSchema}
        onSubmit={handleSave}>
        {({values, errors, handleChange, handleSubmit, dirty}) => (
          <>
            <Styled.SectionsBtnContainer>
              <Styled.BodyHeader>
                <ButtonGroup
                  size="small"
                  variant="outlined"
                  aria-label="primary button group">
                  {sectionNames.map((sectionName, index) => {
                    return (
                      <Button
                        key={index}
                        onClick={handleSelectSectionData(sectionName)}>
                        {sectionName}
                      </Button>
                    );
                  })}
                </ButtonGroup>

                <Button
                  disabled={!dirty}
                  variant="contained"
                  startIcon={<TurnedInIcon />}
                  onClick={handleSubmit}
                  size="medium">
                  Save
                </Button>
              </Styled.BodyHeader>
            </Styled.SectionsBtnContainer>
            <Grid
              container
              style={{
                paddingBottom: '1rem',
              }}>
              {filteredSectionsData &&
                filteredSectionsData.subGroups.map((subGroup) => {
                  const subGroupName =
                    subGroup.subGroupName !== 'subGroupWithoutName'
                      ? subGroup.subGroupName
                      : '';

                  return (
                    <Grid key={subGroupName} item xs={12} md={3}>
                      <SubSection title={subGroupName}>
                        {fields.length > 0 &&
                          subGroup.data.map((itemData) => {
                            const field = fields.find(
                              (f) => f.id === itemData.fieldId,
                            );

                            if (!field) {
                              return null;
                            }

                            const fieldAttrs = getFieldAttrs(
                              field?.type,
                              field,
                            );

                            return (
                              <FormField
                                key={field.id}
                                error={errors[field.name]}
                                onChange={handleChange(field.name)}
                                label={itemData.fieldName}
                                variant="outlined"
                                placeholder={field.placeholder}
                                value={values[field.name]}
                                // value={values.title}
                                fullWidth
                                size="small"
                                {...fieldAttrs}
                              />
                            );
                          })}
                      </SubSection>
                    </Grid>
                  );
                })}
            </Grid>
          </>
        )}
      </Form>
    </Styled.Root>
  );
};

FieldForms.defaultProps = {
  fields: [],
  data: [],
};

export default FieldForms;
