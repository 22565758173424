import {useState} from 'react';
import {getAppInfo} from '@macanta/utils/app';

const useUpload = () => {
  const [loading, setLoading] = useState();

  const callUpload = (files, doItemId) => {
    console.log('callUpload', files);
    setLoading(true);

    return uploadFiles(files, doItemId)
      .then((files) => {
        return files;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {callUpload, loading};
};

const uploadFile = async (formData, url) => {
  console.log('Uploading formData', formData);

  const response = await fetch(url, {
    method: 'POST',
    body: formData,
  });

  return response.json();
};

const uploadFiles = (files, doItemId) => {
  console.log('uploadFiles', files);

  return Promise.all(
    files.map(async (file) => {
      const [appName, apiKey] = getAppInfo();

      const url = `https://${appName}.macantacrm.com/rest/v1/data_object/attache/file/item/${doItemId}`;
      const formData = new FormData();

      formData.append('access_key', apiKey);
      formData.append('CDFileAttachments', file);
      console.log('file to upload', file);
      const {data} = await uploadFile(formData, url);
      const dataFiles = data[doItemId];

      console.log('dataFiles', dataFiles);

      const fileWithThumbnail =
        dataFiles?.find((f) => f.filename === file.name) || {};

      return transformUploadedFile(fileWithThumbnail);
    }),
  );
};

const transformUploadedFile = (fileWithThumbnail) => {
  const newFile = {
    id: fileWithThumbnail.id,
    thumbnail: fileWithThumbnail.thumbnail,
    fileName: fileWithThumbnail.filename,
    downloadUrl: fileWithThumbnail.download_url,
  };

  return newFile;
};

export default useUpload;
