import {useCallback} from 'react';
import {
  LIST_DATA_OBJECT_FIELDS,
  LIST_DATA_OBJECT_ITEMS,
} from '@macanta/graphql/dataObjects';
import {useQuery} from '@apollo/client';

const useDOQuery = (groupId, contactId, email) => {
  const doFieldsQuery = useQuery(LIST_DATA_OBJECT_FIELDS, {
    variables: {
      groupId,
    },
  });

  const doItemsQuery = useQuery(LIST_DATA_OBJECT_ITEMS, {
    variables: {
      groupId,
      contactId,
      email,
    },
  });

  const doFields = doFieldsQuery.data?.listDataObjectFields;
  const doItems = doItemsQuery.data?.listDataObjectItems?.items;

  const loading = Boolean(doFieldsQuery.loading || doItemsQuery.loading);

  const refetch = useCallback(() => {
    doFieldsQuery.refetch();
    doItemsQuery.refetch();
  }, [groupId, contactId, email]);

  console.log('useDOQuery', doFields, doItems);

  return {
    doFields,
    doItems,
    loading,
    refetch,
  };
};

export default useDOQuery;
