import {experimentalStyled as styled} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CardActionAreaComp from '@material-ui/core/CardActionArea';
import CardActionsComp from '@material-ui/core/CardActions';

export const FileBackdrop = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const CardActionArea = styled(CardActionAreaComp)`
  & .card-actions {
    display: none;
  }

  &:hover {
    & .card-actions {
      display: block;
    }
  }
`;

export const CardActions = styled(CardActionsComp)`
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 100%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;
