import React, {useState, useEffect} from 'react';
import {useLocation} from '@reach/router';
import Section from '@macanta/containers/Section';
import DataTable from '@macanta/containers/DataTable';
import Button from '@macanta/components/Button';
import Pagination from '@macanta/components/Pagination';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DOItemForms from './DOItemForms';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import ProgressAlert from '@macanta/containers/ProgressAlert';
import {LIST_RELATIONSHIPS} from '@macanta/graphql/dataObjects';
import {useQuery} from '@apollo/client';
import useDOQuery from './useDOQuery';
import * as Styled from './styles';
import * as NoteTaskHistoryStyled from '@macanta/modules/NoteTaskHistory/styles';

const DO_ITEM_FORM_TABS = [
  {
    label: 'Details',
    value: 'details',
  },
  {
    label: 'Additional Info',
    value: 'additionalInfo',
  },
];

const DataObjectItems = ({contactId, groupId, doTitle, contact}) => {
  const location = useLocation();
  const [showForms, setShowForms] = useState(false);
  const [selectedDOItem, setSelectedDOItem] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedTab, setSelectedTab] = useState(DO_ITEM_FORM_TABS[0].value);
  const [openProgressAlert, setOpenProgressAlert] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const relationshipsQuery = useQuery(LIST_RELATIONSHIPS, {
    variables: {
      groupId,
    },
  });

  const {doItems, doFields, loading, refetch} = useDOQuery(
    groupId,
    contactId,
    contact?.email,
  );

  const handleAddDOItem = () => {
    if (relationshipsQuery.data?.listRelationships) {
      setShowForms(true);
    }
  };

  const handleBack = () => {
    setSelectedDOItem(null);
    setShowForms(false);
  };

  const handleEditDOItem = (item) => {
    if (doItems) {
      // added null check for race condition when user tries to open item from previous DO tab
      // when currently loading new doItems for next selected tab
      const doItem = doItems.find((it) => it.id === item.id);

      setSelectedDOItem(doItem);
      setShowForms(true);
    }
  };

  const handleMutationSuccess = () => {
    refetch();

    setOpenProgressAlert(true);
  };

  const handleCloseProgresAlert = () => {
    setOpenProgressAlert(false);
  };

  const handleSelectTab = (val) => () => {
    setSelectedTab(val);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (doItems && doFields) {
      const selectedFieldLength = doFields.filter((field) => field.showInTable)
        .length;
      const fieldLength =
        selectedFieldLength || (doFields.length > 3 ? 3 : doFields.length);
      const fieldsData = doFields.slice(0, fieldLength);
      const dataSliced = doItems.map(({id, data}) => {
        const flattenedData = data.reduce((acc, d) => {
          acc[d.name] = d.value;
          return acc;
        }, {});

        return {
          ...flattenedData,
          id,
        };
      });

      //Add ID column
      const columnsWithId = [{name: 'ID'}].concat(fieldsData);
      const searchResultsWithId = dataSliced.map((item) => ({
        ...item,
        ID: item.id,
      }));

      setColumns(
        columnsWithId.map((field) => ({
          id: field.name,
          label: field.name,
          minWidth: 170,
        })),
      );
      setSearchResults(searchResultsWithId);
    }
  }, [doItems, doFields]);

  useEffect(() => {
    setSelectedDOItem(null);
    setShowForms(false);
  }, [groupId, location]);

  useEffect(() => {
    setSelectedTab(DO_ITEM_FORM_TABS[0].value);
  }, [showForms]);

  console.log('selectedDOItem', selectedDOItem);

  return (
    <>
      <NoteTaskHistoryStyled.FullHeightGrid container>
        <NoteTaskHistoryStyled.FullHeightFlexGridColumn item xs={12}>
          <Section
            fullHeight
            loading={loading}
            bodyStyle={
              showForms && {
                backgroundColor: '#f5f6fa',
              }
            }
            HeaderLeftComp={
              <Breadcrumbs aria-label="breadcrumb">
                <Styled.TitleCrumbBtn
                  disabled
                  {...(showForms && {
                    startIcon: (
                      <ArrowBackIcon
                        style={{
                          color: '#333',
                        }}
                      />
                    ),
                    onClick: handleBack,
                    disabled: false,
                  })}>
                  <Typography color="textPrimary">{doTitle}</Typography>
                </Styled.TitleCrumbBtn>
                {showForms &&
                  (!selectedDOItem ? (
                    <Typography>Add Item</Typography>
                  ) : (
                    <Typography component="span">
                      Edit{' '}
                      <Typography component="span">
                        ({selectedDOItem.id})
                      </Typography>
                    </Typography>
                  ))}
              </Breadcrumbs>
            }
            HeaderRightComp={
              !showForms ? (
                <Button
                  onClick={handleAddDOItem}
                  size="small"
                  variant="contained"
                  startIcon={<AddIcon />}>
                  Add Item
                </Button>
              ) : (
                <Styled.DOItemNavBtnGroup
                  variant="text"
                  aria-label="text button group">
                  {DO_ITEM_FORM_TABS.map((tab) => {
                    return (
                      <Styled.DOItemNavBtn
                        key={tab.value}
                        onClick={handleSelectTab(tab.value)}
                        style={{
                          color: '#888',
                        }}>
                        {tab.label}
                      </Styled.DOItemNavBtn>
                    );
                  })}
                </Styled.DOItemNavBtnGroup>
              )
            }
            FooterComp={
              !showForms &&
              searchResults.length > 0 && (
                <Pagination
                  table
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={searchResults.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )
            }>
            {!showForms ? (
              <DataTable
                fullHeight
                columns={columns}
                selectable
                data={searchResults}
                onSelectItem={handleEditDOItem}
                hidePagination
                rowsPerPage={rowsPerPage}
                page={page}
                hideEmptyMessage={loading}
              />
            ) : (
              <DOItemForms
                contact={contact}
                contactId={contactId}
                groupId={groupId}
                relationships={relationshipsQuery.data?.listRelationships}
                fields={doFields}
                item={selectedDOItem}
                onSuccess={handleMutationSuccess}
                selectedTab={selectedTab}
              />
            )}
          </Section>
        </NoteTaskHistoryStyled.FullHeightFlexGridColumn>
      </NoteTaskHistoryStyled.FullHeightGrid>
      <ProgressAlert
        open={openProgressAlert}
        message="Saved successfully!"
        onClose={handleCloseProgresAlert}
      />
    </>
  );
};

DataObjectItems.propTypes = {};

export default DataObjectItems;
