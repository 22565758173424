import React, {useState} from 'react';
import {NOTE_TAG} from '@macanta/constants/noteTaskTypes';
import Switch from '@macanta/components/Switch';
import ConfirmationDialog from '@macanta/containers/ConfirmationDialog';
import LoadingIndicator from '@macanta/components/LoadingIndicator';
import {COMPLETE_TASK} from '@macanta/graphql/notesTasks';
import {useMutation} from '@apollo/client';

export const filterNoteTags = (tags) => {
  return tags.filter((v) => !NOTE_TAG.TYPES.includes(v.toLowerCase()));
};

const CompleteTaskForms = ({id, completed}) => {
  const [isComplete, setIsComplete] = useState(completed);
  const [openDialog, setOpenDialog] = useState(false);

  const [callCompleteTaskMutation, completeTaskMutation] = useMutation(
    COMPLETE_TASK,
    {
      onCompleted() {
        setIsComplete(true);
        setOpenDialog(false);
      },
      onError() {},
    },
  );

  const handleChange = () => {
    setOpenDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
  };

  const handleCompleteTask = (itemId) => () => {
    callCompleteTaskMutation({
      variables: {
        completeTaskInput: {id: itemId},
        __mutationkey: 'completeTaskInput',
      },
    });
  };

  return (
    <>
      <Switch
        color="success"
        checked={isComplete}
        disabled={isComplete}
        onChange={handleChange}
      />
      <ConfirmationDialog
        open={!!openDialog}
        onClose={handleCloseDeleteDialog}
        title="Complete task?"
        description="You won't be able to undo this action."
        actions={[
          {
            label: 'Cancel',
            onClick: handleCloseDeleteDialog,
            style: {
              color: '#aaa',
            },
          },
          {
            label: 'Complete',
            onClick: handleCompleteTask(id),
          },
        ]}
      />
      <LoadingIndicator modal loading={completeTaskMutation.loading} />
    </>
  );
};

CompleteTaskForms.propTypes = {};

export default CompleteTaskForms;
