import {gql} from '@apollo/client';
import {TASK_ATTRIBUTES} from './fragments';

export default gql`
  mutation completeTask($completeTaskInput: CompleteTaskInput!) {
    completeTask(input: $completeTaskInput) {
      ...taskResults
    }
  }
  ${TASK_ATTRIBUTES}
`;
