import React, {useState, useEffect, useCallback} from 'react';
import {useNavigate} from '@reach/router';
import Typography from '@material-ui/core/Typography';
import ConfirmationDialog from '@macanta/containers/ConfirmationDialog';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Section from '@macanta/containers/Section';
import {sortArrayByKeyCondition} from '@macanta/utils/array';
import FormField from '@macanta/containers/FormField';
import {sortArrayByPriority} from '@macanta/utils/array';
import uniq from 'lodash/uniq';
import flatMap from 'lodash/flatMap';
import {
  getContactRelationships,
  getFilteredRelationshipsByLimit,
  getRelationshipOptions,
} from './transforms';
import ConnectAnotherContactFormsBtn from './ConnectAnotherContactFormsBtn';
import debounce from 'lodash/debounce';
import Button from '@macanta/components/Button';
import * as Styled from './styles';

const Relationships = ({
  connectedContacts: relationshipConnectedContacts,
  contactId,
  relationships: data,
  onSave,
}) => {
  const navigate = useNavigate();

  const [connectedContacts, setConnectedContacts] = useState(
    relationshipConnectedContacts,
  );
  const [sortedContacts, setSortedContacts] = useState(null);
  const [filteredRelationships, setFilteredRelationships] = useState(null);
  const [connectedContactValues, setConnectedContactValues] = useState({});
  const [contactToBeDeleted, setContactToBeDeleted] = useState(null);

  const allRelationshipRoles = data?.map((r) => r.role);

  const handleSave = async (
    connectedContactId,
    relationships,
    disableLoading,
  ) => {
    await onSave(
      {
        connectedContactId,
        relationships,
      },
      disableLoading,
    );
  };

  const handleDebouncedSave = useCallback(debounce(handleSave, 1000), []);

  const handleChange = (connectedContactId) => (event) => {
    const relationships = event.target.value;

    const updatedConnectedContactValues = {
      ...connectedContactValues,
      [connectedContactId]: relationships,
    };

    const allUsedRelationships = uniq(
      flatMap(Object.values(updatedConnectedContactValues)),
    );

    const filteredRelationshipsByLimit = getFilteredRelationshipsByLimit(
      allUsedRelationships,
      data,
    );

    setConnectedContactValues(updatedConnectedContactValues);
    setFilteredRelationships(filteredRelationshipsByLimit);

    handleDebouncedSave(connectedContactId, relationships, true);
  };

  const handleConnectContact = async (
    {id, firstName, lastName, email},
    relationships,
  ) => {
    await handleSave(id, relationships);
    setConnectedContacts((state) =>
      state.concat({
        contactId: id,
        firstName,
        lastName,
        email,
        relationships: relationships.map((role) => ({role})),
      }),
    );
  };

  const handleDeleteConnectedContact = (connectedContact) => () => {
    setContactToBeDeleted(connectedContact);
  };

  const handleCloseDeleteDialog = () => {
    setContactToBeDeleted(null);
  };

  const handleDeleteContact = (connectedContact) => async () => {
    console.log('handleDeleteContact', connectedContact, connectedContacts);
    await handleSave(connectedContact.id, []);
    setConnectedContacts((state) =>
      state.filter((item) => item.contactId !== connectedContact.id),
    );
    handleCloseDeleteDialog();
  };

  const handleRenderValue = (selected) => {
    const sortedRenderedValue = sortArrayByPriority(
      selected,
      null,
      allRelationshipRoles,
    );

    return sortedRenderedValue.join(', ');
  };

  const handleOtherContactNameClick = (contactId) => () => {
    navigate(`/app/dashboard/${contactId}/notes`);
  };

  useEffect(() => {
    setSortedContacts(
      sortArrayByKeyCondition(
        connectedContacts,
        'contactId',
        (value) => value === contactId,
      ),
    );
  }, [connectedContacts, contactId]);

  useEffect(() => {
    if (sortedContacts) {
      let initCustomFilters = {};
      sortedContacts.forEach(
        ({contactId: connectedContactId, relationships}) => {
          initCustomFilters[connectedContactId] = relationships.map(
            (r) => r.role,
          );
        },
      );

      setConnectedContactValues(initCustomFilters);
    }
  }, [sortedContacts]);

  useEffect(() => {
    if (sortedContacts && data.length) {
      const contactRelationships = getContactRelationships(sortedContacts);
      const filteredRelationshipsByLimit = getFilteredRelationshipsByLimit(
        contactRelationships,
        data,
      );

      setFilteredRelationships(filteredRelationshipsByLimit);
    }
  }, [sortedContacts, data]);

  if (!filteredRelationships) {
    return null;
  }

  return (
    <>
      <Section
        fullHeight
        style={{
          marginTop: 0,
        }}
        bodyStyle={{
          padding: '1rem',
        }}
        title="Relationships"
        HeaderRightComp={
          <ConnectAnotherContactFormsBtn
            connectedContactValues={connectedContactValues}
            onRenderValue={handleRenderValue}
            relationshipOptions={getRelationshipOptions(
              allRelationshipRoles,
              [],
              filteredRelationships,
            )}
            onSuccess={handleConnectContact}
          />
        }>
        {sortedContacts.map(
          ({contactId: connectedContactId, firstName, lastName, email}) => {
            const isSelectedContact = connectedContactId === contactId;
            const name = `${firstName} ${lastName}`.trim();
            const selectedRelationships =
              connectedContactValues[connectedContactId] || [];
            const relationshipOptions = getRelationshipOptions(
              allRelationshipRoles,
              selectedRelationships,
              filteredRelationships,
            );
            const label = isSelectedContact ? (
              name
            ) : (
              <>
                <Button
                  onClick={handleOtherContactNameClick(connectedContactId)}
                  style={{
                    color: '#2196f3',
                    padding: 0,
                  }}>
                  {name}
                </Button>
                <Typography
                  variant="caption"
                  style={{display: 'block', color: '#aaa'}}>
                  {email}
                </Typography>
              </>
            );
            const TopRightComp = !isSelectedContact && (
              <Styled.TopRightComp
                aria-haspopup="true"
                onClick={handleDeleteConnectedContact({
                  id: connectedContactId,
                  name: `${firstName} ${lastName}`.trim(),
                  email,
                })}>
                <DeleteForeverIcon />
              </Styled.TopRightComp>
            );

            return (
              <React.Fragment key={connectedContactId}>
                <FormField
                  select
                  multiple
                  options={relationshipOptions}
                  renderValue={handleRenderValue}
                  // error={errors[field.name]}
                  onChange={handleChange(connectedContactId)}
                  label={label}
                  variant="outlined"
                  // placeholder={field.placeholder}
                  // value={values[field.name]}
                  value={selectedRelationships}
                  fullWidth
                  size="small"
                  TopRightComp={TopRightComp}
                  // {...fieldAttrs}
                />

                {isSelectedContact && connectedContacts.length > 1 && (
                  <Styled.SubHeader>Other Related Contacts</Styled.SubHeader>
                )}
              </React.Fragment>
            );
          },
        )}
      </Section>
      <ConfirmationDialog
        open={!!contactToBeDeleted}
        onClose={handleCloseDeleteDialog}
        title="Are you sure?"
        description={`Remove ${contactToBeDeleted?.name} as connected contact?`}
        actions={[
          {
            label: 'Cancel',
            onClick: handleCloseDeleteDialog,
            style: {
              color: '#aaa',
            },
          },
          {
            label: 'Delete',
            onClick: handleDeleteContact(contactToBeDeleted),
          },
        ]}
      />
    </>
  );
};

Relationships.defaultProps = {
  relationships: [],
};

export default Relationships;
