import * as yup from 'yup';

export const taskValidationSchema = yup.object().shape({
  userEmail: yup.string().required('Required'),
  title: yup.string().required('Required'),
  note: yup.string(),
  tags: yup.array(),
  actionDate: yup.string().required('Required'),
});

export default taskValidationSchema;
