import {experimentalStyled as styled} from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ListComp from '@macanta/components/List';

export const Root = styled(ListComp)``;

export const ListItem = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const EmptyMessage = styled(Typography)`
  padding-top: ${({theme}) => theme.spacing(4)};
`;
