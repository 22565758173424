import {gql} from '@apollo/client';
import {TASK_ATTRIBUTES} from './fragments';

export default gql`
  query listTasks(
    $appName: String!
    $apiKey: String!
    $contactId: ID!
    $page: Int
    $limit: Int
    $filter: String
    $filterBy: String
  ) {
    listTasks(
      appName: $appName
      apiKey: $apiKey
      contactId: $contactId
      page: $page
      limit: $limit
      filter: $filter
      filterBy: $filterBy
    ) {
      items {
        ...taskResults
      }
      count
      total
    }
  }
  ${TASK_ATTRIBUTES}
`;
